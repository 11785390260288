//
// Steps
// --------------------------------------------------


// stylelint-disable
// Container

.steps {
  display: flex;
  padding-top: $step-number-size * .5;
}


// Individual step

.step {
  flex-basis: 0;
  flex-grow: 1;
  text: {
    align: center;
    decoration: none;
  }
}

.step-progress {
  position: relative;
  width: 100%;
  height: $step-progress-height;
}

.step-progress-start,
.step-progress-end {
  position: absolute;
  top: 50%;
  width: 50%;
  height: .125rem;
  margin-top: -.0625rem;
  overflow: hidden;
  &::before {
    display: block;
    width: 62rem;
    height: 100%;
    background-color: $step-progress-bg;
    content: '';
  }
}
.step-progress-start { left: 0; }
.step-progress-end { left: 50%; }
.steps:not(.steps-vertical) {
  .step:first-child .step-progress .step-progress-start { display: none; }
  .step:last-child .step-progress .step-progress-end { display: none; }
}

.step-number {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  width: $step-number-size;
  height: $step-number-size;
  margin: {
    top: -($step-number-size * .5);
    left: -($step-number-size * .5);
  }
  transition: $step-number-transition;
  border-radius: $step-number-border-radius;
  background: $step-number-bg;
  color: $step-number-color;
  font: {
    size: $step-number-font-size;
    weight: $step-number-font-weight;
  }
  box-shadow: $step-number-box-shadow;
  line-height: $step-number-size;
}

.step-label {
  padding-top: ($step-number-size * .5) + .5rem;
  color: $step-label-color;
  font-size: $step-label-font-size;
}


// Active step

.step.active {
  .step-progress {
    .step-progress-start,
    .step-progress-end {
      height: $step-progress-height;
      background-color: $step-active-progress-bg;
      &::before { display: none; }
    }
  }
  .step-number {
    background-color: $step-active-number-bg;
    color: $step-active-number-color;
    box-shadow: $step-active-box-shadow;
  }
}


// Vertical steps

.steps.steps-vertical {
  flex-direction: column;

  .step {
    display: flex;

    // Active step
    &.active {
      .step-progress {
        .step-progress-end {
          width: $border-width;
          height: 100%;
        }
      }
    }
    &:last-child .step-label {
      margin-bottom: initial;
    }
  }

  .step-progress {
    flex-shrink: 0;
    width: $step-number-size;
    height: auto;
  }

  .step-progress-start { display: none; }
  .step-progress-end {
    position: absolute;
    top: 0;
    left: 50%;
    width: .125rem;
    height: 100%;
    transform: translateX(-50%);
    &::before {
      width: 100%;
      height: 100%;
      background-color: $step-progress-bg;
    }
  }

  .step-number {
    position: relative;
    top: initial;
    left: initial;
    margin: {
      top: -.125rem;
      left: initial;
    }
  }

  .step-label {
    margin: {
      top: -.125rem;
      bottom: $spacer * 1.5;
      left: $spacer * 3.5;
    }
    padding-top: initial;
    font-size: $font-size-base;
    text-align: left;

    @include media-breakpoint-down(md) {
      margin-left: $spacer;
    }
  }
}


// Links

a.step {
  &:hover .step-number:not(.active) {
    background-color: $step-active-number-bg;
    color: $step-active-number-color;
    box-shadow: $step-active-box-shadow;
  }
  &.active { pointer-events: none; }
}
// stylelint-enable
