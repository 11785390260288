@charset "UTF-8";
/*
 * Ecomcharge Website
 * Copyright 2024 Ecomcharge
 * Website styles
*/
/* stylelint-disable @stylistic/value-list-max-empty-lines, @stylistic/function-max-empty-lines, declaration-no-important */
[class^=rounded].overflow-hidden,
[class*=" rounded"].overflow-hidden {
  transform: translateZ(0);
}

.text-line-clamp {
  --ec-text-line-clamp-lines: 2;
  --ec-text-line-clamp-height: calc(3em - 1px);
  display: -webkit-box;
  height: var(--ec-text-line-clamp-height);
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--ec-text-line-clamp-lines);
}

.mx-n-container {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.px-container {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.mt-scroll {
  --ec-scroll-margin-top: 96px;
  scroll-margin-top: var(--ec-scroll-margin-top) !important;
}

/* stylelint-enable @stylistic/value-list-max-empty-lines, @stylistic/function-max-empty-lines, declaration-no-important */
:root,
[data-bs-theme=light] {
  --ec-primary: #ff4e02;
  --ec-secondary: #64748b;
  --ec-success: #44b288;
  --ec-info: #44a5b2;
  --ec-warning: #efb572;
  --ec-danger: #ec5a5a;
  --ec-light: #fff;
  --ec-dark: #1e293b;
  --ec-primary-rgb: 255, 78, 2;
  --ec-secondary-rgb: 100, 116, 139;
  --ec-success-rgb: 68, 178, 136;
  --ec-info-rgb: 68, 165, 178;
  --ec-warning-rgb: 239, 181, 114;
  --ec-danger-rgb: 236, 90, 90;
  --ec-light-rgb: 255, 255, 255;
  --ec-dark-rgb: 30, 41, 59;
  --ec-primary-text-emphasis: rgb(206, 61.8814229249, 0);
  --ec-secondary-text-emphasis: #334155;
  --ec-success-text-emphasis: rgb(53.9024390244, 141.0975609756, 107.8048780488);
  --ec-info-text-emphasis: rgb(53.9024390244, 130.7926829268, 141.0975609756);
  --ec-warning-text-emphasis: rgb(233.8025477707, 156.9617834395, 68.1974522293);
  --ec-danger-text-emphasis: rgb(230.7336956522, 44.2663043478, 44.2663043478);
  --ec-light-text-emphasis: #334155;
  --ec-dark-text-emphasis: #020617;
  --ec-primary-bg-subtle: rgb(255, 228.45, 217.05);
  --ec-secondary-bg-subtle: #f8fafc;
  --ec-success-bg-subtle: rgb(226.95, 243.45, 237.15);
  --ec-info-bg-subtle: rgb(208.25, 232.5, 235.75);
  --ec-warning-bg-subtle: rgb(252.6, 243.9, 233.85);
  --ec-danger-bg-subtle: rgb(252.15, 230.25, 230.25);
  --ec-light-bg-subtle: #fff;
  --ec-dark-bg-subtle: rgb(232.5, 233.6, 235.4);
  --ec-primary-border-subtle: rgb(255, 201.9, 179.1);
  --ec-secondary-border-subtle: #e2e8f0;
  --ec-success-border-subtle: rgb(180.2, 224.2, 207.4);
  --ec-info-border-subtle: rgb(180.2, 219, 224.2);
  --ec-warning-border-subtle: rgb(250.2, 232.8, 212.7);
  --ec-danger-border-subtle: rgb(247.4, 189, 189);
  --ec-light-border-subtle: #f1f5f9;
  --ec-dark-border-subtle: #cbd5e1;
  --ec-white-rgb: 255, 255, 255;
  --ec-black-rgb: 0, 0, 0;
  --ec-font-sans-serif: "Poppins", sans-serif;
  --ec-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --ec-gradient: linear-gradient(0deg, #fff 0, #ecf2f8 50%, #fff 100%);
  --ec-root-font-size: 1rem;
  --ec-body-font-family: var(--ec-font-sans-serif);
  --ec-body-font-size: 1rem;
  --ec-body-font-weight: 300;
  --ec-body-line-height: 1.5;
  --ec-body-color: #020617;
  --ec-body-color-rgb: 2, 6, 23;
  --ec-body-bg: #fff;
  --ec-body-bg-rgb: 255, 255, 255;
  --ec-emphasis-color: #1e293b;
  --ec-emphasis-color-rgb: 30, 41, 59;
  --ec-secondary-color: #475569;
  --ec-secondary-color-rgb: 71, 85, 105;
  --ec-secondary-bg: #f8fafc;
  --ec-secondary-bg-rgb: 248, 250, 252;
  --ec-tertiary-color: #94a3b8;
  --ec-tertiary-color-rgb: 148, 163, 184;
  --ec-tertiary-bg: #f1f5f9;
  --ec-tertiary-bg-rgb: 241, 245, 249;
  --ec-heading-color: #020617;
  --ec-link-color: #020617;
  --ec-link-color-rgb: 2, 6, 23;
  --ec-link-decoration: underline;
  --ec-link-hover-color: #1e293b;
  --ec-link-hover-color-rgb: 30, 41, 59;
  --ec-link-hover-decoration: none;
  --ec-code-color: #d63384;
  --ec-highlight-color: #020617;
  --ec-highlight-bg: rgb(255, 242.6, 205.4);
  --ec-border-width: 1px;
  --ec-border-style: solid;
  --ec-border-color: #f1f5f9;
  --ec-border-color-translucent: rgba(0, 0, 0, 0.1);
  --ec-border-radius: 0.5rem;
  --ec-border-radius-sm: calc(var(--ec-border-radius) * 0.75);
  --ec-border-radius-lg: calc(var(--ec-border-radius) * 1.5);
  --ec-border-radius-xl: calc(var(--ec-border-radius) * 2);
  --ec-border-radius-xxl: calc(var(--ec-border-radius) * 3);
  --ec-border-radius-2xl: var(--ec-border-radius-xxl);
  --ec-border-radius-pill: 50rem;
  --ec-box-shadow: 0 0.5rem 1.5rem 0 rgba(50, 73, 96, 0.06);
  --ec-box-shadow-sm: 0 0.375rem 1.5rem rgba(103, 111, 123, 0.06);
  --ec-box-shadow-lg: 0 1.125rem 3rem -0.375rem rgba(103, 111, 123, 0.12);
  --ec-box-shadow-inset: unset;
  --ec-focus-ring-width: 0.25rem;
  --ec-focus-ring-opacity: 0.1;
  --ec-focus-ring-color: rgba(2, 6, 23, 0.1);
  --ec-form-valid-color: #44b288;
  --ec-form-valid-border-color: #44b288;
  --ec-form-invalid-color: #ec5a5a;
  --ec-form-invalid-border-color: #ec5a5a;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: var(--ec-root-font-size);
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--ec-body-font-family);
  font-size: var(--ec-body-font-size);
  font-weight: var(--ec-body-font-weight);
  line-height: var(--ec-body-line-height);
  color: var(--ec-body-color);
  text-align: var(--ec-body-text-align);
  background-color: var(--ec-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1.5rem 0;
  color: var(--ec-border-color);
  border: 0;
  border-top: var(--ec-border-width) solid;
  opacity: 1;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--ec-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.35rem + 1.2vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2.25rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 500;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--ec-highlight-color);
  background-color: var(--ec-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--ec-link-color-rgb), var(--ec-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --ec-link-color-rgb: var(--ec-link-hover-color-rgb);
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--ec-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--ec-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--ec-body-bg);
  background-color: var(--ec-body-color);
  border-radius: calc(var(--ec-border-radius) * 0.75);
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: var(--ec-secondary-color);
  text-align: left;
}

th {
  font-weight: 500;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: inherit;
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: calc(1.275rem + 0.3vw);
  font-weight: 300;
}
@media (min-width: 1200px) {
  .lead {
    font-size: 1.5rem;
  }
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 600;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 600;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 600;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 600;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 600;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.4rem + 1.8vw);
  font-weight: 600;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.75rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 1rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  color: var(--ec-body-color);
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--ec-body-bg);
  border: var(--ec-border-width) solid var(--ec-border-color);
  border-radius: var(--ec-border-radius);
  box-shadow: var(--ec-box-shadow-sm);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--ec-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --ec-gutter-x: 2rem;
  --ec-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--ec-gutter-x) * 0.5);
  padding-left: calc(var(--ec-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 500px) {
  .container-sm, .container {
    max-width: 95%;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 95%;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 95%;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 95%;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1328px;
  }
}
:root {
  --ec-breakpoint-xs: 0;
  --ec-breakpoint-sm: 500px;
  --ec-breakpoint-md: 768px;
  --ec-breakpoint-lg: 992px;
  --ec-breakpoint-xl: 1200px;
  --ec-breakpoint-xxl: 1400px;
}

.row {
  --ec-gutter-x: 1.5rem;
  --ec-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--ec-gutter-y));
  margin-right: calc(-0.5 * var(--ec-gutter-x));
  margin-left: calc(-0.5 * var(--ec-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--ec-gutter-x) * 0.5);
  padding-left: calc(var(--ec-gutter-x) * 0.5);
  margin-top: var(--ec-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --ec-gutter-x: 0;
}

.g-0,
.gy-0 {
  --ec-gutter-y: 0;
}

.g-1,
.gx-1 {
  --ec-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --ec-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --ec-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --ec-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --ec-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --ec-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --ec-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --ec-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --ec-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --ec-gutter-y: 3rem;
}

@media (min-width: 500px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --ec-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --ec-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --ec-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --ec-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --ec-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --ec-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --ec-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --ec-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --ec-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --ec-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --ec-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --ec-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --ec-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --ec-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --ec-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --ec-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --ec-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --ec-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --ec-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --ec-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --ec-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --ec-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --ec-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --ec-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --ec-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --ec-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --ec-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --ec-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --ec-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --ec-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --ec-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --ec-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --ec-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --ec-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --ec-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --ec-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --ec-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --ec-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --ec-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --ec-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --ec-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --ec-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --ec-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --ec-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --ec-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --ec-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --ec-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --ec-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --ec-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --ec-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --ec-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --ec-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --ec-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --ec-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --ec-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --ec-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --ec-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --ec-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --ec-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --ec-gutter-y: 3rem;
  }
}
.table {
  --ec-table-color-type: initial;
  --ec-table-bg-type: initial;
  --ec-table-color-state: initial;
  --ec-table-bg-state: initial;
  --ec-table-color: var(--ec-emphasis-color);
  --ec-table-bg: var(--ec-body-bg);
  --ec-table-border-color: var(--ec-border-color);
  --ec-table-accent-bg: transparent;
  --ec-table-striped-color: var(--ec-emphasis-color);
  --ec-table-striped-bg: rgba(51, 65, 85, 0.05);
  --ec-table-active-color: var(--ec-emphasis-color);
  --ec-table-active-bg: rgba(51, 65, 85, 0.1);
  --ec-table-hover-color: var(--ec-emphasis-color);
  --ec-table-hover-bg: rgba(51, 65, 85, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--ec-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.75rem 0.75rem;
  color: var(--ec-table-color-state, var(--ec-table-color-type, var(--ec-table-color)));
  background-color: var(--ec-table-bg);
  border-bottom-width: var(--ec-border-width);
  box-shadow: inset 0 0 0 9999px var(--ec-table-bg-state, var(--ec-table-bg-type, var(--ec-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--ec-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.375rem 0.375rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--ec-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--ec-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --ec-table-color-type: var(--ec-table-striped-color);
  --ec-table-bg-type: var(--ec-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --ec-table-color-type: var(--ec-table-striped-color);
  --ec-table-bg-type: var(--ec-table-striped-bg);
}

.table-active {
  --ec-table-color-state: var(--ec-table-active-color);
  --ec-table-bg-state: var(--ec-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --ec-table-color-state: var(--ec-table-hover-color);
  --ec-table-bg-state: var(--ec-table-hover-bg);
}

.table-dark {
  --ec-table-color: #fff;
  --ec-table-bg: #1e293b;
  --ec-table-border-color: rgb(75, 83.8, 98.2);
  --ec-table-striped-bg: rgb(41.25, 51.7, 68.8);
  --ec-table-striped-color: #fff;
  --ec-table-active-bg: rgb(52.5, 62.4, 78.6);
  --ec-table-active-color: #fff;
  --ec-table-hover-bg: rgb(46.875, 57.05, 73.7);
  --ec-table-hover-color: #fff;
  color: var(--ec-table-color);
  border-color: var(--ec-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 499.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  font-weight: 400;
  color: #020617;
}

.col-form-label {
  padding-top: calc(0.53rem + var(--ec-border-width));
  padding-bottom: calc(0.53rem + var(--ec-border-width));
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 400;
  line-height: 1.5;
  color: #020617;
}

.col-form-label-lg {
  padding-top: calc(0.685rem + var(--ec-border-width));
  padding-bottom: calc(0.685rem + var(--ec-border-width));
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(0.375rem + var(--ec-border-width));
  padding-bottom: calc(0.375rem + var(--ec-border-width));
  font-size: 0.75rem;
}

.form-text {
  margin-top: 0.375rem;
  font-size: 0.75rem;
  color: var(--ec-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.53rem 1rem;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;
  color: var(--ec-body-secondary-color);
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: var(--ec-border-width) solid #cbd5e1;
  border-radius: var(--ec-border-radius-lg);
  box-shadow: var(--ec-box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--ec-body-secondary-color);
  background-color: #fff;
  border-color: #020617;
  outline: 0;
  box-shadow: var(--ec-box-shadow-inset), unset;
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: #94a3b8;
  opacity: 1;
}
.form-control:disabled {
  color: var(--ec-tertiary-color);
  background-color: var(--ec-tertiary-bg);
  border-color: var(--ec-border-color);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.53rem 1rem;
  margin: -0.53rem -1rem;
  margin-inline-end: 1rem;
  color: var(--ec-body-secondary-color);
  background-color: var(--ec-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--ec-border-width);
  border-radius: 0;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, opacity 0.25s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--ec-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.53rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--ec-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--ec-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.75rem + calc(var(--ec-border-width) * 2));
  padding: 0.375rem 0.875rem;
  font-size: 0.75rem;
  border-radius: var(--ec-border-radius-lg);
}
.form-control-sm::file-selector-button {
  padding: 0.375rem 0.875rem;
  margin: -0.375rem -0.875rem;
  margin-inline-end: 0.875rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1.37rem + calc(var(--ec-border-width) * 2));
  padding: 0.685rem 1.125rem;
  font-size: 1rem;
  border-radius: var(--ec-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.685rem 1.125rem;
  margin: -0.685rem -1.125rem;
  margin-inline-end: 1.125rem;
}

textarea.form-control {
  min-height: calc(1.5em + 1.06rem + calc(var(--ec-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.75rem + calc(var(--ec-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1.37rem + calc(var(--ec-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 1.06rem + calc(var(--ec-border-width) * 2));
  padding: 0.53rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--ec-border-radius-lg);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--ec-border-radius-lg);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.75rem + calc(var(--ec-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1.37rem + calc(var(--ec-border-width) * 2));
}

.form-select {
  --ec-form-select-bg-img: url("data:image/svg+xml,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14 5L7.64706 11L2 5' stroke='%23475569'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.53rem 2.25rem 0.53rem 1rem;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;
  color: var(--ec-body-secondary-color);
  appearance: none;
  background-color: #fff;
  background-image: var(--ec-form-select-bg-img), var(--ec-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 15px 11px;
  border: var(--ec-border-width) solid #cbd5e1;
  border-radius: var(--ec-border-radius-lg);
  box-shadow: var(--ec-box-shadow-inset);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #020617;
  outline: 0;
  box-shadow: var(--ec-box-shadow-inset), 0 0 0 0 rgba(2, 6, 23, 0.1);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--ec-tertiary-bg);
  border-color: var(--ec-border-color);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--ec-body-secondary-color);
}

.form-select-sm {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.875rem;
  font-size: 0.75rem;
  border-radius: var(--ec-border-radius-lg);
}

.form-select-lg {
  padding-top: 0.685rem;
  padding-bottom: 0.685rem;
  padding-left: 1.125rem;
  font-size: 1rem;
  border-radius: var(--ec-border-radius-lg);
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.25rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --ec-form-check-bg: transparent;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--ec-form-check-bg);
  background-image: var(--ec-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--ec-border-width) solid #94a3b8;
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25rem;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #020617;
  outline: 0;
  box-shadow: none;
}
.form-check-input:checked {
  background-color: #ff4e02;
  border-color: #ff4e02;
}
.form-check-input:checked[type=checkbox] {
  --ec-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --ec-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #ff4e02;
  border-color: #ff4e02;
  --ec-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 3.375rem;
}
.form-switch .form-check-input {
  --ec-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  width: 2.75rem;
  margin-left: -3.375rem;
  background-image: var(--ec-form-switch-bg);
  background-position: left center;
  border-radius: 2.75rem;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --ec-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --ec-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 3.375rem;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -3.375rem;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 1;
}

.form-range {
  width: 100%;
  height: 0.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, unset;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, unset;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 0.5rem;
  height: 0.5rem;
  margin-top: -0.1875rem;
  appearance: none;
  background-color: var(--ec-body-bg);
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0 0 0.125rem var(--ec-emphasis-color);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: var(--ec-emphasis-color);
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.125rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--ec-border-color);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--ec-box-shadow-inset);
}
.form-range::-moz-range-thumb {
  width: 0.5rem;
  height: 0.5rem;
  appearance: none;
  background-color: var(--ec-body-bg);
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0 0 0.125rem var(--ec-emphasis-color);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: var(--ec-emphasis-color);
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.125rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--ec-border-color);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: var(--ec-box-shadow-inset);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--ec-secondary-bg);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--ec-secondary-bg);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--ec-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--ec-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 1rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--ec-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 1rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--ec-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.5rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: #fff;
  border-radius: var(--ec-border-radius-lg);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--ec-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--ec-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #475569;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--ec-tertiary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.53rem 1rem;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;
  color: var(--ec-body-secondary-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--ec-tertiary-bg);
  border: var(--ec-border-width) solid #cbd5e1;
  border-radius: var(--ec-border-radius-lg);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.685rem 1.125rem;
  font-size: 1rem;
  border-radius: var(--ec-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.375rem 0.875rem;
  font-size: 0.75rem;
  border-radius: var(--ec-border-radius-lg);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3.25rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--ec-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.375rem;
  font-size: 0.75rem;
  color: var(--ec-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.75rem;
  color: #fff;
  background-color: var(--ec-success);
  border-radius: var(--ec-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--ec-form-valid-border-color);
  padding-right: calc(1.5em + 1.06rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2344b288'%3e%3cpath d='M10.2,3.8c1.8-0.4,3.6-0.2,5.3,0.5c0.4,0.2,0.9,0,1.1-0.4s0-0.9-0.4-1.1c0,0,0,0,0,0c-5-2.2-10.9,0-13.2,5.1s0,10.9,5.1,13.2s10.9,0,13.2-5.1c0.6-1.3,0.9-2.7,0.9-4.1v-0.8c0-0.4-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8l0,0V12c0,4.6-3.8,8.4-8.4,8.4S3.6,16.6,3.6,12C3.6,8.1,6.3,4.7,10.2,3.8z'/%3e%3cpath d='M21.8,5.2c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0c0,0,0,0,0,0L12,12.7l-2.2-2.2c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l2.8,2.8c0.3,0.3,0.8,0.3,1.1,0c0,0,0,0,0,0L21.8,5.2L21.8,5.2z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.265rem) center;
  background-size: calc(0.75em + 0.53rem) calc(0.75em + 0.53rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--ec-form-valid-border-color);
  box-shadow: var(--ec-box-shadow-inset), 0 0 0 0 rgba(var(--ec-success-rgb), 0.1);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1.06rem);
  background-position: top calc(0.375em + 0.265rem) right calc(0.375em + 0.265rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--ec-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --ec-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%2344b288'%3e%3cpath d='M10.2,3.8c1.8-0.4,3.6-0.2,5.3,0.5c0.4,0.2,0.9,0,1.1-0.4s0-0.9-0.4-1.1c0,0,0,0,0,0c-5-2.2-10.9,0-13.2,5.1s0,10.9,5.1,13.2s10.9,0,13.2-5.1c0.6-1.3,0.9-2.7,0.9-4.1v-0.8c0-0.4-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8l0,0V12c0,4.6-3.8,8.4-8.4,8.4S3.6,16.6,3.6,12C3.6,8.1,6.3,4.7,10.2,3.8z'/%3e%3cpath d='M21.8,5.2c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0c0,0,0,0,0,0L12,12.7l-2.2-2.2c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l2.8,2.8c0.3,0.3,0.8,0.3,1.1,0c0,0,0,0,0,0L21.8,5.2L21.8,5.2z'/%3e%3c/svg%3e");
  padding-right: 4.75rem;
  background-position: right 1rem center, center right 2.25rem;
  background-size: 15px 11px, calc(0.75em + 0.53rem) calc(0.75em + 0.53rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--ec-form-valid-border-color);
  box-shadow: var(--ec-box-shadow-inset), 0 0 0 0 rgba(var(--ec-success-rgb), 0.1);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 1.06rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--ec-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--ec-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0 rgba(var(--ec-success-rgb), 0.1);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--ec-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.375rem;
  font-size: 0.75rem;
  color: var(--ec-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.75rem;
  color: #fff;
  background-color: var(--ec-danger);
  border-radius: var(--ec-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--ec-form-invalid-border-color);
  padding-right: calc(1.5em + 1.06rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ec5a5a'%3e%3cpath d='M12,1.5C6.2,1.5,1.5,6.2,1.5,12S6.2,22.5,12,22.5S22.5,17.8,22.5,12S17.8,1.5,12,1.5z M12,20.7c-4.8,0-8.7-4-8.7-8.7S7.2,3.3,12,3.3s8.7,4,8.7,8.7S16.8,20.7,12,20.7z'/%3e%3cpath d='M15.7,14.5c0.3,0.3,0.3,0.9,0,1.2s-0.9,0.3-1.2,0L12,13.2l-2.5,2.5c-0.3,0.3-0.9,0.3-1.2,0s-0.3-0.9,0-1.2l2.5-2.5L8.3,9.5c-0.4-0.3-0.4-0.9,0-1.2c0.3-0.4,0.9-0.4,1.2,0l2.5,2.5l2.5-2.5c0.3-0.3,0.9-0.3,1.2,0s0.3,0.9,0,1.2L13.2,12L15.7,14.5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.265rem) center;
  background-size: calc(0.75em + 0.53rem) calc(0.75em + 0.53rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--ec-form-invalid-border-color);
  box-shadow: var(--ec-box-shadow-inset), 0 0 0 0 rgba(var(--ec-danger-rgb), 0.1);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1.06rem);
  background-position: top calc(0.375em + 0.265rem) right calc(0.375em + 0.265rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--ec-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --ec-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ec5a5a'%3e%3cpath d='M12,1.5C6.2,1.5,1.5,6.2,1.5,12S6.2,22.5,12,22.5S22.5,17.8,22.5,12S17.8,1.5,12,1.5z M12,20.7c-4.8,0-8.7-4-8.7-8.7S7.2,3.3,12,3.3s8.7,4,8.7,8.7S16.8,20.7,12,20.7z'/%3e%3cpath d='M15.7,14.5c0.3,0.3,0.3,0.9,0,1.2s-0.9,0.3-1.2,0L12,13.2l-2.5,2.5c-0.3,0.3-0.9,0.3-1.2,0s-0.3-0.9,0-1.2l2.5-2.5L8.3,9.5c-0.4-0.3-0.4-0.9,0-1.2c0.3-0.4,0.9-0.4,1.2,0l2.5,2.5l2.5-2.5c0.3-0.3,0.9-0.3,1.2,0s0.3,0.9,0,1.2L13.2,12L15.7,14.5z'/%3e%3c/svg%3e");
  padding-right: 4.75rem;
  background-position: right 1rem center, center right 2.25rem;
  background-size: 15px 11px, calc(0.75em + 0.53rem) calc(0.75em + 0.53rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--ec-form-invalid-border-color);
  box-shadow: var(--ec-box-shadow-inset), 0 0 0 0 rgba(var(--ec-danger-rgb), 0.1);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 1.06rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--ec-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--ec-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0 rgba(var(--ec-danger-rgb), 0.1);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--ec-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --ec-btn-padding-x: 1.5rem;
  --ec-btn-padding-y: 0.75rem;
  --ec-btn-font-family: ;
  --ec-btn-font-size: 0.875rem;
  --ec-btn-font-weight: 500;
  --ec-btn-line-height: 1.125rem;
  --ec-btn-color: var(--ec-component-color);
  --ec-btn-bg: transparent;
  --ec-btn-border-width: var(--ec-border-width);
  --ec-btn-border-color: transparent;
  --ec-btn-border-radius: var(--ec-border-radius-lg);
  --ec-btn-hover-border-color: transparent;
  --ec-btn-box-shadow: none;
  --ec-btn-disabled-opacity: 1;
  --ec-btn-focus-box-shadow: 0 0 0 0 rgba(var(--ec-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--ec-btn-padding-y) var(--ec-btn-padding-x);
  font-family: var(--ec-btn-font-family);
  font-size: var(--ec-btn-font-size);
  font-weight: var(--ec-btn-font-weight);
  line-height: var(--ec-btn-line-height);
  color: var(--ec-btn-color);
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--ec-btn-border-width) solid var(--ec-btn-border-color);
  border-radius: var(--ec-btn-border-radius);
  background-color: var(--ec-btn-bg);
  box-shadow: var(--ec-btn-box-shadow);
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, opacity 0.25s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--ec-btn-hover-color);
  background-color: var(--ec-btn-hover-bg);
  border-color: var(--ec-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--ec-btn-color);
  background-color: var(--ec-btn-bg);
  border-color: var(--ec-btn-border-color);
}
.btn:focus-visible {
  color: var(--ec-btn-hover-color);
  background-color: var(--ec-btn-hover-bg);
  border-color: var(--ec-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--ec-btn-box-shadow), var(--ec-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--ec-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--ec-btn-box-shadow), var(--ec-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--ec-btn-active-color);
  background-color: var(--ec-btn-active-bg);
  border-color: var(--ec-btn-active-border-color);
  box-shadow: var(--ec-btn-active-shadow);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--ec-btn-active-shadow), var(--ec-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--ec-btn-active-shadow), var(--ec-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--ec-btn-disabled-color);
  pointer-events: none;
  background-color: var(--ec-btn-disabled-bg);
  border-color: var(--ec-btn-disabled-border-color);
  opacity: var(--ec-btn-disabled-opacity);
  box-shadow: none;
}

.btn-primary {
  --ec-btn-color: #fff;
  --ec-btn-bg: #ff4e02;
  --ec-btn-border-color: #ff4e02;
  --ec-btn-hover-color: #fff;
  --ec-btn-hover-bg: rgb(216.75, 66.3, 1.7);
  --ec-btn-hover-border-color: rgb(204, 62.4, 1.6);
  --ec-btn-focus-shadow-rgb: 255, 105, 40;
  --ec-btn-active-color: #fff;
  --ec-btn-active-bg: rgb(204, 62.4, 1.6);
  --ec-btn-active-border-color: rgb(191.25, 58.5, 1.5);
  --ec-btn-active-shadow: none;
  --ec-btn-disabled-color: #fff;
  --ec-btn-disabled-bg: #ff4e02;
  --ec-btn-disabled-border-color: #ff4e02;
}

.btn-secondary {
  --ec-btn-color: #fff;
  --ec-btn-bg: #64748b;
  --ec-btn-border-color: #64748b;
  --ec-btn-hover-color: #fff;
  --ec-btn-hover-bg: rgb(85, 98.6, 118.15);
  --ec-btn-hover-border-color: rgb(80, 92.8, 111.2);
  --ec-btn-focus-shadow-rgb: 123, 137, 156;
  --ec-btn-active-color: #fff;
  --ec-btn-active-bg: rgb(80, 92.8, 111.2);
  --ec-btn-active-border-color: rgb(75, 87, 104.25);
  --ec-btn-active-shadow: none;
  --ec-btn-disabled-color: #fff;
  --ec-btn-disabled-bg: #64748b;
  --ec-btn-disabled-border-color: #64748b;
}

.btn-success {
  --ec-btn-color: #fff;
  --ec-btn-bg: #44b288;
  --ec-btn-border-color: #44b288;
  --ec-btn-hover-color: #fff;
  --ec-btn-hover-bg: rgb(57.8, 151.3, 115.6);
  --ec-btn-hover-border-color: rgb(54.4, 142.4, 108.8);
  --ec-btn-focus-shadow-rgb: 96, 190, 154;
  --ec-btn-active-color: #fff;
  --ec-btn-active-bg: rgb(54.4, 142.4, 108.8);
  --ec-btn-active-border-color: rgb(51, 133.5, 102);
  --ec-btn-active-shadow: none;
  --ec-btn-disabled-color: #fff;
  --ec-btn-disabled-bg: #44b288;
  --ec-btn-disabled-border-color: #44b288;
}

.btn-info {
  --ec-btn-color: #fff;
  --ec-btn-bg: #44a5b2;
  --ec-btn-border-color: #44a5b2;
  --ec-btn-hover-color: #fff;
  --ec-btn-hover-bg: rgb(57.8, 140.25, 151.3);
  --ec-btn-hover-border-color: rgb(54.4, 132, 142.4);
  --ec-btn-focus-shadow-rgb: 96, 179, 190;
  --ec-btn-active-color: #fff;
  --ec-btn-active-bg: rgb(54.4, 132, 142.4);
  --ec-btn-active-border-color: rgb(51, 123.75, 133.5);
  --ec-btn-active-shadow: none;
  --ec-btn-disabled-color: #fff;
  --ec-btn-disabled-bg: #44a5b2;
  --ec-btn-disabled-border-color: #44a5b2;
}

.btn-warning {
  --ec-btn-color: #000;
  --ec-btn-bg: #efb572;
  --ec-btn-border-color: #efb572;
  --ec-btn-hover-color: #000;
  --ec-btn-hover-bg: rgb(241.4, 192.1, 135.15);
  --ec-btn-hover-border-color: rgb(240.6, 188.4, 128.1);
  --ec-btn-focus-shadow-rgb: 203, 154, 97;
  --ec-btn-active-color: #000;
  --ec-btn-active-bg: rgb(242.2, 195.8, 142.2);
  --ec-btn-active-border-color: rgb(240.6, 188.4, 128.1);
  --ec-btn-active-shadow: none;
  --ec-btn-disabled-color: #000;
  --ec-btn-disabled-bg: #efb572;
  --ec-btn-disabled-border-color: #efb572;
}

.btn-danger {
  --ec-btn-color: #fff;
  --ec-btn-bg: #ec5a5a;
  --ec-btn-border-color: #ec5a5a;
  --ec-btn-hover-color: #fff;
  --ec-btn-hover-bg: rgb(200.6, 76.5, 76.5);
  --ec-btn-hover-border-color: rgb(188.8, 72, 72);
  --ec-btn-focus-shadow-rgb: 239, 115, 115;
  --ec-btn-active-color: #fff;
  --ec-btn-active-bg: rgb(188.8, 72, 72);
  --ec-btn-active-border-color: rgb(177, 67.5, 67.5);
  --ec-btn-active-shadow: none;
  --ec-btn-disabled-color: #fff;
  --ec-btn-disabled-bg: #ec5a5a;
  --ec-btn-disabled-border-color: #ec5a5a;
}

.btn-light {
  --ec-btn-color: #000;
  --ec-btn-bg: #fff;
  --ec-btn-border-color: #fff;
  --ec-btn-hover-color: #000;
  --ec-btn-hover-bg: rgb(216.75, 216.75, 216.75);
  --ec-btn-hover-border-color: #cccccc;
  --ec-btn-focus-shadow-rgb: 217, 217, 217;
  --ec-btn-active-color: #000;
  --ec-btn-active-bg: #cccccc;
  --ec-btn-active-border-color: rgb(191.25, 191.25, 191.25);
  --ec-btn-active-shadow: none;
  --ec-btn-disabled-color: #000;
  --ec-btn-disabled-bg: #fff;
  --ec-btn-disabled-border-color: #fff;
}

.btn-dark {
  --ec-btn-color: #fff;
  --ec-btn-bg: #1e293b;
  --ec-btn-border-color: #1e293b;
  --ec-btn-hover-color: #fff;
  --ec-btn-hover-bg: rgb(63.75, 73.1, 88.4);
  --ec-btn-hover-border-color: rgb(52.5, 62.4, 78.6);
  --ec-btn-focus-shadow-rgb: 64, 73, 88;
  --ec-btn-active-color: #fff;
  --ec-btn-active-bg: rgb(75, 83.8, 98.2);
  --ec-btn-active-border-color: rgb(52.5, 62.4, 78.6);
  --ec-btn-active-shadow: none;
  --ec-btn-disabled-color: #fff;
  --ec-btn-disabled-bg: #1e293b;
  --ec-btn-disabled-border-color: #1e293b;
}

.btn-outline-primary {
  --ec-btn-color: #ff4e02;
  --ec-btn-border-color: #ff4e02;
  --ec-btn-hover-color: #fff;
  --ec-btn-hover-bg: #ff4e02;
  --ec-btn-hover-border-color: #ff4e02;
  --ec-btn-focus-shadow-rgb: 255, 78, 2;
  --ec-btn-active-color: #fff;
  --ec-btn-active-bg: #ff4e02;
  --ec-btn-active-border-color: #ff4e02;
  --ec-btn-active-shadow: none;
  --ec-btn-disabled-color: #ff4e02;
  --ec-btn-disabled-bg: transparent;
  --ec-btn-disabled-border-color: #ff4e02;
  --ec-gradient: none;
}

.btn-outline-secondary {
  --ec-btn-color: #64748b;
  --ec-btn-border-color: #64748b;
  --ec-btn-hover-color: #fff;
  --ec-btn-hover-bg: #64748b;
  --ec-btn-hover-border-color: #64748b;
  --ec-btn-focus-shadow-rgb: 100, 116, 139;
  --ec-btn-active-color: #fff;
  --ec-btn-active-bg: #64748b;
  --ec-btn-active-border-color: #64748b;
  --ec-btn-active-shadow: none;
  --ec-btn-disabled-color: #64748b;
  --ec-btn-disabled-bg: transparent;
  --ec-btn-disabled-border-color: #64748b;
  --ec-gradient: none;
}

.btn-outline-success {
  --ec-btn-color: #44b288;
  --ec-btn-border-color: #44b288;
  --ec-btn-hover-color: #fff;
  --ec-btn-hover-bg: #44b288;
  --ec-btn-hover-border-color: #44b288;
  --ec-btn-focus-shadow-rgb: 68, 178, 136;
  --ec-btn-active-color: #fff;
  --ec-btn-active-bg: #44b288;
  --ec-btn-active-border-color: #44b288;
  --ec-btn-active-shadow: none;
  --ec-btn-disabled-color: #44b288;
  --ec-btn-disabled-bg: transparent;
  --ec-btn-disabled-border-color: #44b288;
  --ec-gradient: none;
}

.btn-outline-info {
  --ec-btn-color: #44a5b2;
  --ec-btn-border-color: #44a5b2;
  --ec-btn-hover-color: #fff;
  --ec-btn-hover-bg: #44a5b2;
  --ec-btn-hover-border-color: #44a5b2;
  --ec-btn-focus-shadow-rgb: 68, 165, 178;
  --ec-btn-active-color: #fff;
  --ec-btn-active-bg: #44a5b2;
  --ec-btn-active-border-color: #44a5b2;
  --ec-btn-active-shadow: none;
  --ec-btn-disabled-color: #44a5b2;
  --ec-btn-disabled-bg: transparent;
  --ec-btn-disabled-border-color: #44a5b2;
  --ec-gradient: none;
}

.btn-outline-warning {
  --ec-btn-color: #efb572;
  --ec-btn-border-color: #efb572;
  --ec-btn-hover-color: #000;
  --ec-btn-hover-bg: #efb572;
  --ec-btn-hover-border-color: #efb572;
  --ec-btn-focus-shadow-rgb: 239, 181, 114;
  --ec-btn-active-color: #000;
  --ec-btn-active-bg: #efb572;
  --ec-btn-active-border-color: #efb572;
  --ec-btn-active-shadow: none;
  --ec-btn-disabled-color: #efb572;
  --ec-btn-disabled-bg: transparent;
  --ec-btn-disabled-border-color: #efb572;
  --ec-gradient: none;
}

.btn-outline-danger {
  --ec-btn-color: #ec5a5a;
  --ec-btn-border-color: #ec5a5a;
  --ec-btn-hover-color: #fff;
  --ec-btn-hover-bg: #ec5a5a;
  --ec-btn-hover-border-color: #ec5a5a;
  --ec-btn-focus-shadow-rgb: 236, 90, 90;
  --ec-btn-active-color: #fff;
  --ec-btn-active-bg: #ec5a5a;
  --ec-btn-active-border-color: #ec5a5a;
  --ec-btn-active-shadow: none;
  --ec-btn-disabled-color: #ec5a5a;
  --ec-btn-disabled-bg: transparent;
  --ec-btn-disabled-border-color: #ec5a5a;
  --ec-gradient: none;
}

.btn-outline-light {
  --ec-btn-color: #fff;
  --ec-btn-border-color: #fff;
  --ec-btn-hover-color: #000;
  --ec-btn-hover-bg: #fff;
  --ec-btn-hover-border-color: #fff;
  --ec-btn-focus-shadow-rgb: 255, 255, 255;
  --ec-btn-active-color: #000;
  --ec-btn-active-bg: #fff;
  --ec-btn-active-border-color: #fff;
  --ec-btn-active-shadow: none;
  --ec-btn-disabled-color: #fff;
  --ec-btn-disabled-bg: transparent;
  --ec-btn-disabled-border-color: #fff;
  --ec-gradient: none;
}

.btn-outline-dark {
  --ec-btn-color: #1e293b;
  --ec-btn-border-color: #1e293b;
  --ec-btn-hover-color: #fff;
  --ec-btn-hover-bg: #1e293b;
  --ec-btn-hover-border-color: #1e293b;
  --ec-btn-focus-shadow-rgb: 30, 41, 59;
  --ec-btn-active-color: #fff;
  --ec-btn-active-bg: #1e293b;
  --ec-btn-active-border-color: #1e293b;
  --ec-btn-active-shadow: none;
  --ec-btn-disabled-color: #1e293b;
  --ec-btn-disabled-bg: transparent;
  --ec-btn-disabled-border-color: #1e293b;
  --ec-gradient: none;
}

.btn-link {
  --ec-btn-font-weight: 400;
  --ec-btn-color: var(--ec-link-color);
  --ec-btn-bg: transparent;
  --ec-btn-border-color: transparent;
  --ec-btn-hover-color: var(--ec-link-hover-color);
  --ec-btn-hover-border-color: transparent;
  --ec-btn-active-color: var(--ec-link-hover-color);
  --ec-btn-active-border-color: transparent;
  --ec-btn-disabled-color: var(--ec-component-disabled-color);
  --ec-btn-disabled-border-color: transparent;
  --ec-btn-box-shadow: 0 0 0 #000;
  --ec-btn-focus-shadow-rgb: 40, 43, 58;
  text-decoration: underline;
}
.btn-link:hover, .btn-link:focus-visible {
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--ec-btn-color);
}
.btn-link:hover {
  color: var(--ec-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --ec-btn-padding-y: 1.125rem;
  --ec-btn-padding-x: 1.75rem;
  --ec-btn-font-size: 1rem;
  --ec-btn-border-radius: var(--ec-border-radius-xl);
}

.btn-sm, .btn-group-sm > .btn {
  --ec-btn-padding-y: 0.5rem;
  --ec-btn-padding-x: 1rem;
  --ec-btn-font-size: 0.75rem;
  --ec-btn-border-radius: var(--ec-border-radius);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.15em;
  vertical-align: 1.08375em;
  content: "";
  border-top: 1.275em solid;
  border-right: 1.275em solid transparent;
  border-bottom: 0;
  border-left: 1.275em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --ec-dropdown-zindex: 1000;
  --ec-dropdown-min-width: 12.5rem;
  --ec-dropdown-padding-x: 0.75rem;
  --ec-dropdown-padding-y: 0.75rem;
  --ec-dropdown-spacer: 0.25rem;
  --ec-dropdown-font-size: 0.875rem;
  --ec-dropdown-color: var(--ec-component-color);
  --ec-dropdown-bg: var(--ec-body-bg);
  --ec-dropdown-border-color: var(--ec-light-border-subtle);
  --ec-dropdown-border-radius: var(--ec-border-radius);
  --ec-dropdown-border-width: var(--ec-border-width);
  --ec-dropdown-inner-border-radius: calc(var(--ec-border-radius) - var(--ec-border-width));
  --ec-dropdown-divider-bg: var(--ec-border-color);
  --ec-dropdown-divider-margin-y: 0.375rem;
  --ec-dropdown-box-shadow: var(--ec-box-shadow);
  --ec-dropdown-link-color: var(--ec-component-color);
  --ec-dropdown-link-hover-color: var(--ec-component-hover-color);
  --ec-dropdown-link-hover-bg: var(--ec-component-hover-bg);
  --ec-dropdown-link-active-color: var(--ec-component-active-color);
  --ec-dropdown-link-active-bg: var(--ec-component-active-bg);
  --ec-dropdown-link-disabled-color: var(--ec-component-disabled-color);
  --ec-dropdown-item-padding-x: 0.75rem;
  --ec-dropdown-item-padding-y: 0.5rem;
  --ec-dropdown-header-color: var(--ec-heading-color);
  --ec-dropdown-header-padding-x: 0.75rem;
  --ec-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--ec-dropdown-zindex);
  display: none;
  min-width: var(--ec-dropdown-min-width);
  padding: var(--ec-dropdown-padding-y) var(--ec-dropdown-padding-x);
  margin: 0;
  font-size: var(--ec-dropdown-font-size);
  color: var(--ec-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--ec-dropdown-bg);
  background-clip: padding-box;
  border: var(--ec-dropdown-border-width) solid var(--ec-dropdown-border-color);
  border-radius: var(--ec-dropdown-border-radius);
  box-shadow: var(--ec-dropdown-box-shadow);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--ec-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 500px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--ec-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.15em;
  vertical-align: 1.08375em;
  content: "";
  border-top: 0;
  border-right: 1.275em solid transparent;
  border-bottom: 1.275em solid;
  border-left: 1.275em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--ec-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.15em;
  vertical-align: 1.08375em;
  content: "";
  border-top: 1.275em solid transparent;
  border-right: 0;
  border-bottom: 1.275em solid transparent;
  border-left: 1.275em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--ec-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.15em;
  vertical-align: 1.08375em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.15em;
  vertical-align: 1.08375em;
  content: "";
  border-top: 1.275em solid transparent;
  border-right: 1.275em solid;
  border-bottom: 1.275em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--ec-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--ec-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--ec-dropdown-item-padding-y) var(--ec-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--ec-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--ec-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--ec-dropdown-link-hover-color);
  background-color: var(--ec-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--ec-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--ec-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--ec-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--ec-dropdown-header-padding-y) var(--ec-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--ec-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--ec-dropdown-item-padding-y) var(--ec-dropdown-item-padding-x);
  color: var(--ec-dropdown-link-color);
}

.dropdown-menu-dark {
  --ec-dropdown-color: #cbd5e1;
  --ec-dropdown-bg: #1e293b;
  --ec-dropdown-border-color: var(--ec-light-border-subtle);
  --ec-dropdown-box-shadow: ;
  --ec-dropdown-link-color: #cbd5e1;
  --ec-dropdown-link-hover-color: #fff;
  --ec-dropdown-divider-bg: var(--ec-border-color);
  --ec-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --ec-dropdown-link-active-color: var(--ec-component-active-color);
  --ec-dropdown-link-active-bg: var(--ec-component-active-bg);
  --ec-dropdown-link-disabled-color: #64748b;
  --ec-dropdown-header-color: #64748b;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--ec-border-radius-lg);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--ec-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: none;
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--ec-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --ec-nav-link-padding-x: 1.25rem;
  --ec-nav-link-padding-y: 0.75rem;
  --ec-nav-link-font-size: 0.875rem;
  --ec-nav-link-font-weight: 500;
  --ec-nav-link-color: var(--ec-component-color);
  --ec-nav-link-hover-color: var(--ec-component-hover-color);
  --ec-nav-link-disabled-color: var(--ec-component-disabled-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--ec-nav-link-padding-y) var(--ec-nav-link-padding-x);
  font-size: var(--ec-nav-link-font-size);
  font-weight: var(--ec-nav-link-font-weight);
  color: var(--ec-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--ec-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--ec-focus-ring-color);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--ec-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --ec-nav-tabs-border-width: 2px;
  --ec-nav-tabs-border-color: transparent;
  --ec-nav-tabs-border-radius: calc(var(--ec-border-radius) * 1.5);
  --ec-nav-tabs-link-hover-border-color: transparent;
  --ec-nav-tabs-link-active-color: #ff4e02;
  --ec-nav-tabs-link-active-bg: rgb(255, 228.45, 217.05);
  --ec-nav-tabs-link-active-border-color: rgb(255, 201.9, 179.1);
  border-bottom: var(--ec-nav-tabs-border-width) solid var(--ec-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--ec-nav-tabs-border-width));
  border: var(--ec-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--ec-nav-tabs-border-radius);
  border-top-right-radius: var(--ec-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--ec-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--ec-nav-tabs-link-active-color);
  background-color: var(--ec-nav-tabs-link-active-bg);
  border-color: var(--ec-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--ec-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --ec-nav-pills-border-radius: 0.75rem;
  --ec-nav-pills-link-active-color: var(--ec-component-active-color);
  --ec-nav-pills-link-active-bg: var(--ec-component-active-bg);
}
.nav-pills .nav-link {
  border-radius: var(--ec-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--ec-nav-pills-link-active-color);
  background-color: var(--ec-nav-pills-link-active-bg);
}

.nav-underline {
  --ec-nav-underline-gap: 0.5rem 1.75rem;
  --ec-nav-underline-border-width: var(--ec-border-width);
  --ec-nav-underline-link-active-color: var(--ec-component-active-color);
  gap: var(--ec-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--ec-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--ec-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --ec-navbar-padding-x: 0;
  --ec-navbar-padding-y: 0.75rem;
  --ec-navbar-color: #020617;
  --ec-navbar-hover-color: #020617;
  --ec-navbar-disabled-color: #f8fafc;
  --ec-navbar-active-color: #020617;
  --ec-navbar-brand-padding-y: 0.375rem;
  --ec-navbar-brand-margin-end: 1rem;
  --ec-navbar-brand-font-size: 1.5rem;
  --ec-navbar-brand-color: #020617;
  --ec-navbar-brand-hover-color: #020617;
  --ec-navbar-nav-link-padding-x: 1.25rem;
  --ec-navbar-toggler-padding-y: 0.625rem;
  --ec-navbar-toggler-padding-x: 0.25rem;
  --ec-navbar-toggler-font-size: 1.125rem;
  --ec-navbar-toggler-icon-bg: initial;
  --ec-navbar-toggler-border-color: transparent;
  --ec-navbar-toggler-border-radius: 0;
  --ec-navbar-toggler-focus-width: 0;
  --ec-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--ec-navbar-padding-y) var(--ec-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--ec-navbar-brand-padding-y);
  padding-bottom: var(--ec-navbar-brand-padding-y);
  margin-right: var(--ec-navbar-brand-margin-end);
  font-size: var(--ec-navbar-brand-font-size);
  color: var(--ec-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--ec-navbar-brand-hover-color);
}

.navbar-nav {
  --ec-nav-link-padding-x: 0;
  --ec-nav-link-padding-y: 0.75rem;
  --ec-nav-link-font-size: 0.875rem;
  --ec-nav-link-font-weight: 500;
  --ec-nav-link-color: var(--ec-navbar-color);
  --ec-nav-link-hover-color: var(--ec-navbar-hover-color);
  --ec-nav-link-disabled-color: var(--ec-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--ec-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: var(--ec-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--ec-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--ec-navbar-toggler-padding-y) var(--ec-navbar-toggler-padding-x);
  font-size: var(--ec-navbar-toggler-font-size);
  line-height: 1;
  color: var(--ec-navbar-color);
  background-color: transparent;
  border: var(--ec-border-width) solid var(--ec-navbar-toggler-border-color);
  border-radius: var(--ec-navbar-toggler-border-radius);
  transition: var(--ec-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--ec-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--ec-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--ec-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 500px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--ec-navbar-nav-link-padding-x);
    padding-left: var(--ec-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--ec-navbar-nav-link-padding-x);
    padding-left: var(--ec-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--ec-navbar-nav-link-padding-x);
    padding-left: var(--ec-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--ec-navbar-nav-link-padding-x);
    padding-left: var(--ec-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--ec-navbar-nav-link-padding-x);
    padding-left: var(--ec-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--ec-navbar-nav-link-padding-x);
  padding-left: var(--ec-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  box-shadow: none;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --ec-navbar-color: rgba(255, 255, 255, 0.55);
  --ec-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --ec-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --ec-navbar-active-color: #fff;
  --ec-navbar-brand-color: #fff;
  --ec-navbar-brand-hover-color: #fff;
  --ec-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --ec-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --ec-card-spacer-y: 1.5rem;
  --ec-card-spacer-x: 1.5rem;
  --ec-card-title-spacer-y: 0.75rem;
  --ec-card-title-color: var(--ec-heading-color);
  --ec-card-subtitle-color: ;
  --ec-card-border-width: var(--ec-border-width);
  --ec-card-border-color: var(--ec-border-color);
  --ec-card-border-radius: var(--ec-border-radius-xl);
  --ec-card-box-shadow: ;
  --ec-card-inner-border-radius: calc(var(--ec-border-radius-xl) - (var(--ec-border-width)));
  --ec-card-cap-padding-y: 1.5rem;
  --ec-card-cap-padding-x: 1.5rem;
  --ec-card-cap-bg: #fff;
  --ec-card-cap-color: ;
  --ec-card-height: ;
  --ec-card-color: ;
  --ec-card-bg: #fff;
  --ec-card-img-overlay-padding: 1rem;
  --ec-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--ec-card-height);
  color: var(--ec-body-color);
  word-wrap: break-word;
  background-color: var(--ec-card-bg);
  background-clip: border-box;
  border: var(--ec-card-border-width) solid var(--ec-card-border-color);
  border-radius: var(--ec-card-border-radius);
  box-shadow: var(--ec-card-box-shadow);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--ec-card-inner-border-radius);
  border-top-right-radius: var(--ec-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--ec-card-inner-border-radius);
  border-bottom-left-radius: var(--ec-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--ec-card-spacer-y) var(--ec-card-spacer-x);
  color: var(--ec-card-color);
}

.card-title {
  margin-bottom: var(--ec-card-title-spacer-y);
  color: var(--ec-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--ec-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--ec-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--ec-card-spacer-x);
}

.card-header {
  padding: var(--ec-card-cap-padding-y) var(--ec-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--ec-card-cap-color);
  background-color: var(--ec-card-cap-bg);
  border-bottom: var(--ec-card-border-width) solid var(--ec-card-border-color);
}
.card-header:first-child {
  border-radius: var(--ec-card-inner-border-radius) var(--ec-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--ec-card-cap-padding-y) var(--ec-card-cap-padding-x);
  color: var(--ec-card-cap-color);
  background-color: var(--ec-card-cap-bg);
  border-top: var(--ec-card-border-width) solid var(--ec-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--ec-card-inner-border-radius) var(--ec-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--ec-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--ec-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--ec-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--ec-card-bg);
  border-bottom-color: var(--ec-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--ec-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--ec-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--ec-card-img-overlay-padding);
  border-radius: var(--ec-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--ec-card-inner-border-radius);
  border-top-right-radius: var(--ec-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--ec-card-inner-border-radius);
  border-bottom-left-radius: var(--ec-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--ec-card-group-margin);
}
@media (min-width: 500px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --ec-accordion-color: var(--ec-body-color);
  --ec-accordion-bg: transparent;
  --ec-accordion-transition: none;
  --ec-accordion-border-color: var(--ec-border-color);
  --ec-accordion-border-width: var(--ec-border-width);
  --ec-accordion-border-radius: 0;
  --ec-accordion-inner-border-radius: 0;
  --ec-accordion-btn-padding-x: 0;
  --ec-accordion-btn-padding-y: 1.25rem;
  --ec-accordion-btn-color: var(--ec-heading-color);
  --ec-accordion-btn-bg: var(--ec-accordion-bg);
  --ec-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23020617'%3e%3cpath d='M3.8,7.9c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7l6.7-6.7c0.4-0.4,1.1-0.4,1.5,0c0.4,0.4,0.4,1.1,0,1.5l-7.4,7.4c-0.4,0.4-1.1,0.4-1.5,0L3.8,9.4C3.3,9.1,3.3,8.4,3.8,7.9z'/%3e%3c/svg%3e");
  --ec-accordion-btn-icon-width: 1em;
  --ec-accordion-btn-icon-transform: rotate(-180deg);
  --ec-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --ec-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23020617'%3e%3cpath d='M3.8,7.9c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7l6.7-6.7c0.4-0.4,1.1-0.4,1.5,0c0.4,0.4,0.4,1.1,0,1.5l-7.4,7.4c-0.4,0.4-1.1,0.4-1.5,0L3.8,9.4C3.3,9.1,3.3,8.4,3.8,7.9z'/%3e%3c/svg%3e");
  --ec-accordion-btn-focus-box-shadow: unset;
  --ec-accordion-body-padding-x: 0;
  --ec-accordion-body-padding-y: 0;
  --ec-accordion-active-color: var(--ec-heading-color);
  --ec-accordion-active-bg: transparent;
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--ec-accordion-btn-padding-y) var(--ec-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--ec-accordion-btn-color);
  text-align: left;
  background-color: var(--ec-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--ec-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--ec-accordion-active-color);
  background-color: var(--ec-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--ec-accordion-border-width)) 0 var(--ec-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--ec-accordion-btn-active-icon);
  transform: var(--ec-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--ec-accordion-btn-icon-width);
  height: var(--ec-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--ec-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--ec-accordion-btn-icon-width);
  transition: var(--ec-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--ec-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--ec-accordion-color);
  background-color: var(--ec-accordion-bg);
  border: var(--ec-accordion-border-width) solid var(--ec-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--ec-accordion-border-radius);
  border-top-right-radius: var(--ec-accordion-border-radius);
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--ec-accordion-inner-border-radius);
  border-top-right-radius: var(--ec-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--ec-accordion-border-radius);
  border-bottom-left-radius: var(--ec-accordion-border-radius);
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--ec-accordion-inner-border-radius);
  border-bottom-left-radius: var(--ec-accordion-inner-border-radius);
}
.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--ec-accordion-border-radius);
  border-bottom-left-radius: var(--ec-accordion-border-radius);
}

.accordion-body {
  padding: var(--ec-accordion-body-padding-y) var(--ec-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}
.accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

.breadcrumb {
  --ec-breadcrumb-padding-x: 0;
  --ec-breadcrumb-padding-y: 0;
  --ec-breadcrumb-margin-bottom: 1rem;
  --ec-breadcrumb-bg: ;
  --ec-breadcrumb-border-radius: ;
  --ec-breadcrumb-divider-color: var(--ec-secondary-color);
  --ec-breadcrumb-item-padding-x: 0.5rem;
  --ec-breadcrumb-item-active-color: var(--ec-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--ec-breadcrumb-padding-y) var(--ec-breadcrumb-padding-x);
  margin-bottom: var(--ec-breadcrumb-margin-bottom);
  font-size: var(--ec-breadcrumb-font-size);
  list-style: none;
  background-color: var(--ec-breadcrumb-bg);
  border-radius: var(--ec-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--ec-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--ec-breadcrumb-item-padding-x);
  color: var(--ec-breadcrumb-divider-color);
  content: var(--ec-breadcrumb-divider, "/") /* rtl: var(--ec-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--ec-breadcrumb-item-active-color);
}

.pagination {
  --ec-pagination-padding-x: 0;
  --ec-pagination-padding-y: 0;
  --ec-pagination-font-size: 0.875rem;
  --ec-pagination-color: var(--ec-component-color);
  --ec-pagination-bg: var(--ec-body-bg);
  --ec-pagination-border-width: var(--ec-border-width);
  --ec-pagination-border-color: #e2e8f0;
  --ec-pagination-border-radius: calc(var(--ec-border-radius) * 1.5);
  --ec-pagination-hover-color: var(--ec-component-hover-color);
  --ec-pagination-hover-bg: var(--ec-component-hover-bg);
  --ec-pagination-hover-border-color: #e2e8f0;
  --ec-pagination-focus-color: var(--ec-link-hover-color);
  --ec-pagination-focus-bg: var(--ec-body-bg);
  --ec-pagination-focus-box-shadow: 0 0 0 0.25rem var(--ec-focus-ring-color);
  --ec-pagination-active-color: var(--ec-component-active-color);
  --ec-pagination-active-bg: var(--ec-component-active-bg);
  --ec-pagination-active-border-color: #e2e8f0;
  --ec-pagination-disabled-color: var(--ec-secondary-color);
  --ec-pagination-disabled-bg: var(--ec-secondary-bg);
  --ec-pagination-disabled-border-color: var(--ec-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--ec-pagination-padding-y) var(--ec-pagination-padding-x);
  font-size: var(--ec-pagination-font-size);
  color: var(--ec-pagination-color);
  text-decoration: none;
  background-color: var(--ec-pagination-bg);
  border: var(--ec-pagination-border-width) solid var(--ec-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--ec-pagination-hover-color);
  background-color: var(--ec-pagination-hover-bg);
  border-color: var(--ec-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--ec-pagination-focus-color);
  background-color: var(--ec-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--ec-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--ec-pagination-active-color);
  background-color: var(--ec-pagination-active-bg);
  border-color: var(--ec-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--ec-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--ec-pagination-disabled-bg);
  border-color: var(--ec-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: 0;
}
.page-item .page-link {
  border-radius: var(--ec-pagination-border-radius);
}

.pagination-lg {
  --ec-pagination-padding-x: 0;
  --ec-pagination-padding-y: 0;
  --ec-pagination-font-size: 1.125rem;
  --ec-pagination-border-radius: var(--ec-border-radius-lg);
}

.pagination-sm {
  --ec-pagination-padding-x: 0;
  --ec-pagination-padding-y: 0;
  --ec-pagination-font-size: 0.875rem;
  --ec-pagination-border-radius: var(--ec-border-radius-sm);
}

.badge {
  --ec-badge-padding-x: 1em;
  --ec-badge-padding-y: 0.5em;
  --ec-badge-font-size: 0.75em;
  --ec-badge-font-weight: 400;
  --ec-badge-color: #fff;
  --ec-badge-border-radius: var(--ec-border-radius);
  display: inline-block;
  padding: var(--ec-badge-padding-y) var(--ec-badge-padding-x);
  font-size: var(--ec-badge-font-size);
  font-weight: var(--ec-badge-font-weight);
  line-height: 1;
  color: var(--ec-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--ec-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --ec-alert-bg: transparent;
  --ec-alert-padding-x: 1rem;
  --ec-alert-padding-y: 1rem;
  --ec-alert-margin-bottom: 1rem;
  --ec-alert-color: inherit;
  --ec-alert-border-color: transparent;
  --ec-alert-border: var(--ec-border-width) solid var(--ec-alert-border-color);
  --ec-alert-border-radius: var(--ec-border-radius);
  --ec-alert-link-color: inherit;
  position: relative;
  padding: var(--ec-alert-padding-y) var(--ec-alert-padding-x);
  margin-bottom: var(--ec-alert-margin-bottom);
  color: var(--ec-alert-color);
  background-color: var(--ec-alert-bg);
  border: var(--ec-alert-border);
  border-radius: var(--ec-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--ec-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --ec-alert-color: var(--ec-primary-text-emphasis);
  --ec-alert-bg: var(--ec-primary-bg-subtle);
  --ec-alert-border-color: var(--ec-primary-border-subtle);
  --ec-alert-link-color: var(--ec-primary-text-emphasis);
}

.alert-secondary {
  --ec-alert-color: var(--ec-secondary-text-emphasis);
  --ec-alert-bg: var(--ec-secondary-bg-subtle);
  --ec-alert-border-color: var(--ec-secondary-border-subtle);
  --ec-alert-link-color: var(--ec-secondary-text-emphasis);
}

.alert-success {
  --ec-alert-color: var(--ec-success-text-emphasis);
  --ec-alert-bg: var(--ec-success-bg-subtle);
  --ec-alert-border-color: var(--ec-success-border-subtle);
  --ec-alert-link-color: var(--ec-success-text-emphasis);
}

.alert-info {
  --ec-alert-color: var(--ec-info-text-emphasis);
  --ec-alert-bg: var(--ec-info-bg-subtle);
  --ec-alert-border-color: var(--ec-info-border-subtle);
  --ec-alert-link-color: var(--ec-info-text-emphasis);
}

.alert-warning {
  --ec-alert-color: var(--ec-warning-text-emphasis);
  --ec-alert-bg: var(--ec-warning-bg-subtle);
  --ec-alert-border-color: var(--ec-warning-border-subtle);
  --ec-alert-link-color: var(--ec-warning-text-emphasis);
}

.alert-danger {
  --ec-alert-color: var(--ec-danger-text-emphasis);
  --ec-alert-bg: var(--ec-danger-bg-subtle);
  --ec-alert-border-color: var(--ec-danger-border-subtle);
  --ec-alert-link-color: var(--ec-danger-text-emphasis);
}

.alert-light {
  --ec-alert-color: var(--ec-light-text-emphasis);
  --ec-alert-bg: var(--ec-light-bg-subtle);
  --ec-alert-border-color: var(--ec-light-border-subtle);
  --ec-alert-link-color: var(--ec-light-text-emphasis);
}

.alert-dark {
  --ec-alert-color: var(--ec-dark-text-emphasis);
  --ec-alert-bg: var(--ec-dark-bg-subtle);
  --ec-alert-border-color: var(--ec-dark-border-subtle);
  --ec-alert-link-color: var(--ec-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --ec-progress-height: 1rem;
  --ec-progress-font-size: 0.75rem;
  --ec-progress-bg: var(--ec-secondary-bg);
  --ec-progress-border-radius: var(--ec-border-radius);
  --ec-progress-box-shadow: var(--ec-box-shadow-inset);
  --ec-progress-bar-color: #fff;
  --ec-progress-bar-bg: #ff4e02;
  --ec-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--ec-progress-height);
  overflow: hidden;
  font-size: var(--ec-progress-font-size);
  background-color: var(--ec-progress-bg);
  border-radius: var(--ec-progress-border-radius);
  box-shadow: var(--ec-progress-box-shadow);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--ec-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--ec-progress-bar-bg);
  transition: var(--ec-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--ec-progress-height) var(--ec-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --ec-list-group-color: var(--ec-body-color);
  --ec-list-group-bg: var(--ec-body-bg);
  --ec-list-group-border-color: var(--ec-border-color);
  --ec-list-group-border-width: var(--ec-border-width);
  --ec-list-group-border-radius: var(--ec-border-radius);
  --ec-list-group-item-padding-x: 1rem;
  --ec-list-group-item-padding-y: 0.5rem;
  --ec-list-group-action-color: var(--ec-secondary-color);
  --ec-list-group-action-hover-color: var(--ec-emphasis-color);
  --ec-list-group-action-hover-bg: var(--ec-tertiary-bg);
  --ec-list-group-action-active-color: var(--ec-body-color);
  --ec-list-group-action-active-bg: var(--ec-secondary-bg);
  --ec-list-group-disabled-color: var(--ec-secondary-color);
  --ec-list-group-disabled-bg: var(--ec-body-bg);
  --ec-list-group-active-color: #020617;
  --ec-list-group-active-bg: #f1f5f9;
  --ec-list-group-active-border-color: #f1f5f9;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--ec-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--ec-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--ec-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--ec-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--ec-list-group-action-active-color);
  background-color: var(--ec-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--ec-list-group-item-padding-y) var(--ec-list-group-item-padding-x);
  color: var(--ec-list-group-color);
  text-decoration: none;
  background-color: var(--ec-list-group-bg);
  border: var(--ec-list-group-border-width) solid var(--ec-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--ec-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--ec-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--ec-list-group-active-color);
  background-color: var(--ec-list-group-active-bg);
  border-color: var(--ec-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--ec-list-group-border-width));
  border-top-width: var(--ec-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--ec-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--ec-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--ec-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--ec-list-group-border-width));
  border-left-width: var(--ec-list-group-border-width);
}

@media (min-width: 500px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--ec-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--ec-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--ec-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--ec-list-group-border-width));
    border-left-width: var(--ec-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--ec-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--ec-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--ec-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--ec-list-group-border-width));
    border-left-width: var(--ec-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--ec-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--ec-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--ec-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--ec-list-group-border-width));
    border-left-width: var(--ec-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--ec-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--ec-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--ec-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--ec-list-group-border-width));
    border-left-width: var(--ec-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--ec-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--ec-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--ec-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--ec-list-group-border-width));
    border-left-width: var(--ec-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--ec-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --ec-list-group-color: var(--ec-primary-text-emphasis);
  --ec-list-group-bg: var(--ec-primary-bg-subtle);
  --ec-list-group-border-color: var(--ec-primary-border-subtle);
  --ec-list-group-action-hover-color: var(--ec-emphasis-color);
  --ec-list-group-action-hover-bg: var(--ec-primary-border-subtle);
  --ec-list-group-action-active-color: var(--ec-emphasis-color);
  --ec-list-group-action-active-bg: var(--ec-primary-border-subtle);
  --ec-list-group-active-color: var(--ec-primary-bg-subtle);
  --ec-list-group-active-bg: var(--ec-primary-text-emphasis);
  --ec-list-group-active-border-color: var(--ec-primary-text-emphasis);
}

.list-group-item-secondary {
  --ec-list-group-color: var(--ec-secondary-text-emphasis);
  --ec-list-group-bg: var(--ec-secondary-bg-subtle);
  --ec-list-group-border-color: var(--ec-secondary-border-subtle);
  --ec-list-group-action-hover-color: var(--ec-emphasis-color);
  --ec-list-group-action-hover-bg: var(--ec-secondary-border-subtle);
  --ec-list-group-action-active-color: var(--ec-emphasis-color);
  --ec-list-group-action-active-bg: var(--ec-secondary-border-subtle);
  --ec-list-group-active-color: var(--ec-secondary-bg-subtle);
  --ec-list-group-active-bg: var(--ec-secondary-text-emphasis);
  --ec-list-group-active-border-color: var(--ec-secondary-text-emphasis);
}

.list-group-item-success {
  --ec-list-group-color: var(--ec-success-text-emphasis);
  --ec-list-group-bg: var(--ec-success-bg-subtle);
  --ec-list-group-border-color: var(--ec-success-border-subtle);
  --ec-list-group-action-hover-color: var(--ec-emphasis-color);
  --ec-list-group-action-hover-bg: var(--ec-success-border-subtle);
  --ec-list-group-action-active-color: var(--ec-emphasis-color);
  --ec-list-group-action-active-bg: var(--ec-success-border-subtle);
  --ec-list-group-active-color: var(--ec-success-bg-subtle);
  --ec-list-group-active-bg: var(--ec-success-text-emphasis);
  --ec-list-group-active-border-color: var(--ec-success-text-emphasis);
}

.list-group-item-info {
  --ec-list-group-color: var(--ec-info-text-emphasis);
  --ec-list-group-bg: var(--ec-info-bg-subtle);
  --ec-list-group-border-color: var(--ec-info-border-subtle);
  --ec-list-group-action-hover-color: var(--ec-emphasis-color);
  --ec-list-group-action-hover-bg: var(--ec-info-border-subtle);
  --ec-list-group-action-active-color: var(--ec-emphasis-color);
  --ec-list-group-action-active-bg: var(--ec-info-border-subtle);
  --ec-list-group-active-color: var(--ec-info-bg-subtle);
  --ec-list-group-active-bg: var(--ec-info-text-emphasis);
  --ec-list-group-active-border-color: var(--ec-info-text-emphasis);
}

.list-group-item-warning {
  --ec-list-group-color: var(--ec-warning-text-emphasis);
  --ec-list-group-bg: var(--ec-warning-bg-subtle);
  --ec-list-group-border-color: var(--ec-warning-border-subtle);
  --ec-list-group-action-hover-color: var(--ec-emphasis-color);
  --ec-list-group-action-hover-bg: var(--ec-warning-border-subtle);
  --ec-list-group-action-active-color: var(--ec-emphasis-color);
  --ec-list-group-action-active-bg: var(--ec-warning-border-subtle);
  --ec-list-group-active-color: var(--ec-warning-bg-subtle);
  --ec-list-group-active-bg: var(--ec-warning-text-emphasis);
  --ec-list-group-active-border-color: var(--ec-warning-text-emphasis);
}

.list-group-item-danger {
  --ec-list-group-color: var(--ec-danger-text-emphasis);
  --ec-list-group-bg: var(--ec-danger-bg-subtle);
  --ec-list-group-border-color: var(--ec-danger-border-subtle);
  --ec-list-group-action-hover-color: var(--ec-emphasis-color);
  --ec-list-group-action-hover-bg: var(--ec-danger-border-subtle);
  --ec-list-group-action-active-color: var(--ec-emphasis-color);
  --ec-list-group-action-active-bg: var(--ec-danger-border-subtle);
  --ec-list-group-active-color: var(--ec-danger-bg-subtle);
  --ec-list-group-active-bg: var(--ec-danger-text-emphasis);
  --ec-list-group-active-border-color: var(--ec-danger-text-emphasis);
}

.list-group-item-light {
  --ec-list-group-color: var(--ec-light-text-emphasis);
  --ec-list-group-bg: var(--ec-light-bg-subtle);
  --ec-list-group-border-color: var(--ec-light-border-subtle);
  --ec-list-group-action-hover-color: var(--ec-emphasis-color);
  --ec-list-group-action-hover-bg: var(--ec-light-border-subtle);
  --ec-list-group-action-active-color: var(--ec-emphasis-color);
  --ec-list-group-action-active-bg: var(--ec-light-border-subtle);
  --ec-list-group-active-color: var(--ec-light-bg-subtle);
  --ec-list-group-active-bg: var(--ec-light-text-emphasis);
  --ec-list-group-active-border-color: var(--ec-light-text-emphasis);
}

.list-group-item-dark {
  --ec-list-group-color: var(--ec-dark-text-emphasis);
  --ec-list-group-bg: var(--ec-dark-bg-subtle);
  --ec-list-group-border-color: var(--ec-dark-border-subtle);
  --ec-list-group-action-hover-color: var(--ec-emphasis-color);
  --ec-list-group-action-hover-bg: var(--ec-dark-border-subtle);
  --ec-list-group-action-active-color: var(--ec-emphasis-color);
  --ec-list-group-action-active-bg: var(--ec-dark-border-subtle);
  --ec-list-group-active-color: var(--ec-dark-bg-subtle);
  --ec-list-group-active-bg: var(--ec-dark-text-emphasis);
  --ec-list-group-active-border-color: var(--ec-dark-text-emphasis);
}

.btn-close {
  --ec-btn-close-color: #020617;
  --ec-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'%3e%3cpath d='M15.281 14.219a.75.75 0 0 1 .22.531.75.75 0 0 1-.22.531.75.75 0 0 1-.531.22.75.75 0 0 1-.531-.22L8 9.06l-6.219 6.22a.75.75 0 0 1-1.061 0 .75.75 0 0 1 0-1.061L6.94 8 .72 1.781a.75.75 0 0 1 0-1.061.75.75 0 0 1 1.061 0L8 6.94 14.22.719a.75.75 0 0 1 1.061 0 .75.75 0 0 1 0 1.061L9.061 8l6.22 6.219z' fill='%23020617'/%3e%3c/svg%3e");
  --ec-btn-close-opacity: 0.5;
  --ec-btn-close-hover-opacity: 1;
  --ec-btn-close-focus-shadow: 0 0 0 0.25rem var(--ec-focus-ring-color);
  --ec-btn-close-focus-opacity: 1;
  --ec-btn-close-disabled-opacity: 0.25;
  --ec-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--ec-btn-close-color);
  background: transparent var(--ec-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.5rem;
  opacity: var(--ec-btn-close-opacity);
}
.btn-close:hover {
  color: var(--ec-btn-close-color);
  text-decoration: none;
  opacity: var(--ec-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--ec-btn-close-focus-shadow);
  opacity: var(--ec-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--ec-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--ec-btn-close-white-filter);
}

.toast {
  --ec-toast-zindex: 1090;
  --ec-toast-padding-x: 0.75rem;
  --ec-toast-padding-y: 0.5rem;
  --ec-toast-spacing: 2rem;
  --ec-toast-max-width: 350px;
  --ec-toast-font-size: 0.875rem;
  --ec-toast-color: ;
  --ec-toast-bg: rgba(var(--ec-body-bg-rgb), 0.85);
  --ec-toast-border-width: var(--ec-border-width);
  --ec-toast-border-color: var(--ec-border-color-translucent);
  --ec-toast-border-radius: var(--ec-border-radius);
  --ec-toast-box-shadow: var(--ec-box-shadow);
  --ec-toast-header-color: var(--ec-secondary-color);
  --ec-toast-header-bg: rgba(var(--ec-body-bg-rgb), 0.85);
  --ec-toast-header-border-color: var(--ec-border-color-translucent);
  width: var(--ec-toast-max-width);
  max-width: 100%;
  font-size: var(--ec-toast-font-size);
  color: var(--ec-toast-color);
  pointer-events: auto;
  background-color: var(--ec-toast-bg);
  background-clip: padding-box;
  border: var(--ec-toast-border-width) solid var(--ec-toast-border-color);
  box-shadow: var(--ec-toast-box-shadow);
  border-radius: var(--ec-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --ec-toast-zindex: 1090;
  position: absolute;
  z-index: var(--ec-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--ec-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--ec-toast-padding-y) var(--ec-toast-padding-x);
  color: var(--ec-toast-header-color);
  background-color: var(--ec-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--ec-toast-border-width) solid var(--ec-toast-header-border-color);
  border-top-left-radius: calc(var(--ec-toast-border-radius) - var(--ec-toast-border-width));
  border-top-right-radius: calc(var(--ec-toast-border-radius) - var(--ec-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--ec-toast-padding-x));
  margin-left: var(--ec-toast-padding-x);
}

.toast-body {
  padding: var(--ec-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --ec-modal-zindex: 1055;
  --ec-modal-width: 500px;
  --ec-modal-padding: 1rem;
  --ec-modal-margin: 0.5rem;
  --ec-modal-color: ;
  --ec-modal-bg: var(--ec-body-bg);
  --ec-modal-border-color: var(--ec-border-color-translucent);
  --ec-modal-border-width: var(--ec-border-width);
  --ec-modal-border-radius: var(--ec-border-radius-lg);
  --ec-modal-box-shadow: var(--ec-box-shadow-sm);
  --ec-modal-inner-border-radius: calc(var(--ec-border-radius-lg) - (var(--ec-border-width)));
  --ec-modal-header-padding-x: 1rem;
  --ec-modal-header-padding-y: 1rem;
  --ec-modal-header-padding: 1rem 1rem;
  --ec-modal-header-border-color: var(--ec-border-color);
  --ec-modal-header-border-width: var(--ec-border-width);
  --ec-modal-title-line-height: 1.5;
  --ec-modal-footer-gap: 0.5rem;
  --ec-modal-footer-bg: ;
  --ec-modal-footer-border-color: var(--ec-border-color);
  --ec-modal-footer-border-width: var(--ec-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--ec-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--ec-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--ec-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--ec-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--ec-modal-color);
  pointer-events: auto;
  background-color: var(--ec-modal-bg);
  background-clip: padding-box;
  border: var(--ec-modal-border-width) solid var(--ec-modal-border-color);
  border-radius: var(--ec-modal-border-radius);
  box-shadow: var(--ec-modal-box-shadow);
  outline: 0;
}

.modal-backdrop {
  --ec-backdrop-zindex: 1050;
  --ec-backdrop-bg: #000;
  --ec-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--ec-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--ec-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--ec-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--ec-modal-header-padding);
  border-bottom: var(--ec-modal-header-border-width) solid var(--ec-modal-header-border-color);
  border-top-left-radius: var(--ec-modal-inner-border-radius);
  border-top-right-radius: var(--ec-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--ec-modal-header-padding-y) * 0.5) calc(var(--ec-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--ec-modal-header-padding-y)) calc(-0.5 * var(--ec-modal-header-padding-x)) calc(-0.5 * var(--ec-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--ec-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--ec-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--ec-modal-padding) - var(--ec-modal-footer-gap) * 0.5);
  background-color: var(--ec-modal-footer-bg);
  border-top: var(--ec-modal-footer-border-width) solid var(--ec-modal-footer-border-color);
  border-bottom-right-radius: var(--ec-modal-inner-border-radius);
  border-bottom-left-radius: var(--ec-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--ec-modal-footer-gap) * 0.5);
}

@media (min-width: 500px) {
  .modal {
    --ec-modal-margin: 1.75rem;
    --ec-modal-box-shadow: var(--ec-box-shadow);
  }
  .modal-dialog {
    max-width: var(--ec-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --ec-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --ec-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --ec-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 499.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --ec-tooltip-zindex: 1080;
  --ec-tooltip-max-width: 200px;
  --ec-tooltip-padding-x: 0.5rem;
  --ec-tooltip-padding-y: 0.25rem;
  --ec-tooltip-margin: ;
  --ec-tooltip-font-size: 0.875rem;
  --ec-tooltip-color: var(--ec-body-bg);
  --ec-tooltip-bg: var(--ec-emphasis-color);
  --ec-tooltip-border-radius: var(--ec-border-radius);
  --ec-tooltip-opacity: 0.9;
  --ec-tooltip-arrow-width: 0.8rem;
  --ec-tooltip-arrow-height: 0.4rem;
  z-index: var(--ec-tooltip-zindex);
  display: block;
  margin: var(--ec-tooltip-margin);
  font-family: var(--ec-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--ec-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--ec-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--ec-tooltip-arrow-width);
  height: var(--ec-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--ec-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--ec-tooltip-arrow-height) calc(var(--ec-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--ec-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--ec-tooltip-arrow-height));
  width: var(--ec-tooltip-arrow-height);
  height: var(--ec-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--ec-tooltip-arrow-width) * 0.5) var(--ec-tooltip-arrow-height) calc(var(--ec-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--ec-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--ec-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--ec-tooltip-arrow-width) * 0.5) var(--ec-tooltip-arrow-height);
  border-bottom-color: var(--ec-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--ec-tooltip-arrow-height));
  width: var(--ec-tooltip-arrow-height);
  height: var(--ec-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--ec-tooltip-arrow-width) * 0.5) 0 calc(var(--ec-tooltip-arrow-width) * 0.5) var(--ec-tooltip-arrow-height);
  border-left-color: var(--ec-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--ec-tooltip-max-width);
  padding: var(--ec-tooltip-padding-y) var(--ec-tooltip-padding-x);
  color: var(--ec-tooltip-color);
  text-align: center;
  background-color: var(--ec-tooltip-bg);
  border-radius: var(--ec-tooltip-border-radius);
}

.popover {
  --ec-popover-zindex: 1070;
  --ec-popover-max-width: 276px;
  --ec-popover-font-size: 0.875rem;
  --ec-popover-bg: var(--ec-body-bg);
  --ec-popover-border-width: var(--ec-border-width);
  --ec-popover-border-color: var(--ec-border-color-translucent);
  --ec-popover-border-radius: var(--ec-border-radius-lg);
  --ec-popover-inner-border-radius: calc(var(--ec-border-radius-lg) - var(--ec-border-width));
  --ec-popover-box-shadow: var(--ec-box-shadow);
  --ec-popover-header-padding-x: 1rem;
  --ec-popover-header-padding-y: 0.5rem;
  --ec-popover-header-font-size: 1rem;
  --ec-popover-header-color: #020617;
  --ec-popover-header-bg: var(--ec-secondary-bg);
  --ec-popover-body-padding-x: 1rem;
  --ec-popover-body-padding-y: 1rem;
  --ec-popover-body-color: var(--ec-body-color);
  --ec-popover-arrow-width: 1rem;
  --ec-popover-arrow-height: 0.5rem;
  --ec-popover-arrow-border: var(--ec-popover-border-color);
  z-index: var(--ec-popover-zindex);
  display: block;
  max-width: var(--ec-popover-max-width);
  font-family: var(--ec-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--ec-popover-font-size);
  word-wrap: break-word;
  background-color: var(--ec-popover-bg);
  background-clip: padding-box;
  border: var(--ec-popover-border-width) solid var(--ec-popover-border-color);
  border-radius: var(--ec-popover-border-radius);
  box-shadow: var(--ec-popover-box-shadow);
}
.popover .popover-arrow {
  display: block;
  width: var(--ec-popover-arrow-width);
  height: var(--ec-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--ec-popover-arrow-height)) - var(--ec-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--ec-popover-arrow-height) calc(var(--ec-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--ec-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--ec-popover-border-width);
  border-top-color: var(--ec-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--ec-popover-arrow-height)) - var(--ec-popover-border-width));
  width: var(--ec-popover-arrow-height);
  height: var(--ec-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--ec-popover-arrow-width) * 0.5) var(--ec-popover-arrow-height) calc(var(--ec-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--ec-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--ec-popover-border-width);
  border-right-color: var(--ec-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--ec-popover-arrow-height)) - var(--ec-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--ec-popover-arrow-width) * 0.5) var(--ec-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--ec-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--ec-popover-border-width);
  border-bottom-color: var(--ec-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--ec-popover-arrow-width);
  margin-left: calc(-0.5 * var(--ec-popover-arrow-width));
  content: "";
  border-bottom: var(--ec-popover-border-width) solid var(--ec-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--ec-popover-arrow-height)) - var(--ec-popover-border-width));
  width: var(--ec-popover-arrow-height);
  height: var(--ec-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--ec-popover-arrow-width) * 0.5) 0 calc(var(--ec-popover-arrow-width) * 0.5) var(--ec-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--ec-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--ec-popover-border-width);
  border-left-color: var(--ec-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--ec-popover-header-padding-y) var(--ec-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--ec-popover-header-font-size);
  color: var(--ec-popover-header-color);
  background-color: var(--ec-popover-header-bg);
  border-bottom: var(--ec-popover-border-width) solid var(--ec-popover-border-color);
  border-top-left-radius: var(--ec-popover-inner-border-radius);
  border-top-right-radius: var(--ec-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--ec-popover-body-padding-y) var(--ec-popover-body-padding-x);
  color: var(--ec-popover-body-color);
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--ec-spinner-width);
  height: var(--ec-spinner-height);
  vertical-align: var(--ec-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--ec-spinner-animation-speed) linear infinite var(--ec-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --ec-spinner-width: 2rem;
  --ec-spinner-height: 2rem;
  --ec-spinner-vertical-align: -0.125em;
  --ec-spinner-border-width: 0.25em;
  --ec-spinner-animation-speed: 0.75s;
  --ec-spinner-animation-name: spinner-border;
  border: var(--ec-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --ec-spinner-width: 1rem;
  --ec-spinner-height: 1rem;
  --ec-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --ec-spinner-width: 2rem;
  --ec-spinner-height: 2rem;
  --ec-spinner-vertical-align: -0.125em;
  --ec-spinner-animation-speed: 0.75s;
  --ec-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --ec-spinner-width: 1rem;
  --ec-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --ec-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --ec-offcanvas-zindex: 1045;
  --ec-offcanvas-width: 400px;
  --ec-offcanvas-height: 30vh;
  --ec-offcanvas-padding-x: 1rem;
  --ec-offcanvas-padding-y: 1rem;
  --ec-offcanvas-color: var(--ec-body-color);
  --ec-offcanvas-bg: var(--ec-body-bg);
  --ec-offcanvas-border-width: var(--ec-border-width);
  --ec-offcanvas-border-color: var(--ec-border-color-translucent);
  --ec-offcanvas-box-shadow: var(--ec-box-shadow-sm);
  --ec-offcanvas-transition: transform 0.3s ease-in-out;
  --ec-offcanvas-title-line-height: 1.5;
}

@media (max-width: 499.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--ec-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--ec-offcanvas-color);
    visibility: hidden;
    background-color: var(--ec-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--ec-offcanvas-box-shadow);
    transition: var(--ec-offcanvas-transition);
  }
}
@media (max-width: 499.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 499.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--ec-offcanvas-width);
    border-right: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--ec-offcanvas-width);
    border-left: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--ec-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--ec-offcanvas-height);
    max-height: 100%;
    border-top: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 500px) {
  .offcanvas-sm {
    --ec-offcanvas-height: auto;
    --ec-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--ec-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--ec-offcanvas-color);
    visibility: hidden;
    background-color: var(--ec-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--ec-offcanvas-box-shadow);
    transition: var(--ec-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--ec-offcanvas-width);
    border-right: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--ec-offcanvas-width);
    border-left: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--ec-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--ec-offcanvas-height);
    max-height: 100%;
    border-top: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --ec-offcanvas-height: auto;
    --ec-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--ec-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--ec-offcanvas-color);
    visibility: hidden;
    background-color: var(--ec-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--ec-offcanvas-box-shadow);
    transition: var(--ec-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--ec-offcanvas-width);
    border-right: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--ec-offcanvas-width);
    border-left: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--ec-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--ec-offcanvas-height);
    max-height: 100%;
    border-top: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --ec-offcanvas-height: auto;
    --ec-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--ec-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--ec-offcanvas-color);
    visibility: hidden;
    background-color: var(--ec-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--ec-offcanvas-box-shadow);
    transition: var(--ec-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--ec-offcanvas-width);
    border-right: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--ec-offcanvas-width);
    border-left: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--ec-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--ec-offcanvas-height);
    max-height: 100%;
    border-top: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --ec-offcanvas-height: auto;
    --ec-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--ec-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--ec-offcanvas-color);
    visibility: hidden;
    background-color: var(--ec-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--ec-offcanvas-box-shadow);
    transition: var(--ec-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--ec-offcanvas-width);
    border-right: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--ec-offcanvas-width);
    border-left: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--ec-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--ec-offcanvas-height);
    max-height: 100%;
    border-top: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --ec-offcanvas-height: auto;
    --ec-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--ec-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--ec-offcanvas-color);
  visibility: hidden;
  background-color: var(--ec-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  box-shadow: var(--ec-offcanvas-box-shadow);
  transition: var(--ec-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--ec-offcanvas-width);
  border-right: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--ec-offcanvas-width);
  border-left: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--ec-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--ec-offcanvas-height);
  max-height: 100%;
  border-top: var(--ec-offcanvas-border-width) solid var(--ec-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--ec-offcanvas-padding-y) var(--ec-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--ec-offcanvas-padding-y) * 0.5) calc(var(--ec-offcanvas-padding-x) * 0.5);
  margin: calc(-0.5 * var(--ec-offcanvas-padding-y)) calc(-0.5 * var(--ec-offcanvas-padding-x)) calc(-0.5 * var(--ec-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--ec-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--ec-offcanvas-padding-y) var(--ec-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--ec-primary-rgb), var(--ec-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--ec-secondary-rgb), var(--ec-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--ec-success-rgb), var(--ec-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(var(--ec-info-rgb), var(--ec-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--ec-warning-rgb), var(--ec-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--ec-danger-rgb), var(--ec-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--ec-light-rgb), var(--ec-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--ec-dark-rgb), var(--ec-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--ec-primary-rgb), var(--ec-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ec-primary-rgb), var(--ec-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(255, 78, 2, var(--ec-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 78, 2, var(--ec-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--ec-secondary-rgb), var(--ec-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ec-secondary-rgb), var(--ec-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(100, 116, 139, var(--ec-link-opacity, 1)) !important;
  text-decoration-color: RGBA(100, 116, 139, var(--ec-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--ec-success-rgb), var(--ec-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ec-success-rgb), var(--ec-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(68, 178, 136, var(--ec-link-opacity, 1)) !important;
  text-decoration-color: RGBA(68, 178, 136, var(--ec-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--ec-info-rgb), var(--ec-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ec-info-rgb), var(--ec-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(68, 165, 178, var(--ec-link-opacity, 1)) !important;
  text-decoration-color: RGBA(68, 165, 178, var(--ec-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--ec-warning-rgb), var(--ec-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ec-warning-rgb), var(--ec-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(239, 181, 114, var(--ec-link-opacity, 1)) !important;
  text-decoration-color: RGBA(239, 181, 114, var(--ec-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--ec-danger-rgb), var(--ec-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ec-danger-rgb), var(--ec-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(236, 90, 90, var(--ec-link-opacity, 1)) !important;
  text-decoration-color: RGBA(236, 90, 90, var(--ec-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--ec-light-rgb), var(--ec-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ec-light-rgb), var(--ec-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(255, 255, 255, var(--ec-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--ec-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--ec-dark-rgb), var(--ec-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ec-dark-rgb), var(--ec-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(30, 41, 59, var(--ec-link-opacity, 1)) !important;
  text-decoration-color: RGBA(30, 41, 59, var(--ec-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--ec-emphasis-color-rgb), var(--ec-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--ec-emphasis-color-rgb), var(--ec-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--ec-emphasis-color-rgb), var(--ec-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--ec-emphasis-color-rgb), var(--ec-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--ec-focus-ring-x, 0) var(--ec-focus-ring-y, 0) var(--ec-focus-ring-blur, 0) var(--ec-focus-ring-width) var(--ec-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--ec-link-color-rgb), var(--ec-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--ec-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--ec-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --ec-aspect-ratio: 100%;
}

.ratio-4x3 {
  --ec-aspect-ratio: 75%;
}

.ratio-16x9 {
  --ec-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --ec-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 500px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--ec-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 1;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--ec-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--ec-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--ec-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --ec-focus-ring-color: rgba(var(--ec-primary-rgb), var(--ec-focus-ring-opacity));
}

.focus-ring-secondary {
  --ec-focus-ring-color: rgba(var(--ec-secondary-rgb), var(--ec-focus-ring-opacity));
}

.focus-ring-success {
  --ec-focus-ring-color: rgba(var(--ec-success-rgb), var(--ec-focus-ring-opacity));
}

.focus-ring-info {
  --ec-focus-ring-color: rgba(var(--ec-info-rgb), var(--ec-focus-ring-opacity));
}

.focus-ring-warning {
  --ec-focus-ring-color: rgba(var(--ec-warning-rgb), var(--ec-focus-ring-opacity));
}

.focus-ring-danger {
  --ec-focus-ring-color: rgba(var(--ec-danger-rgb), var(--ec-focus-ring-opacity));
}

.focus-ring-light {
  --ec-focus-ring-color: rgba(var(--ec-light-rgb), var(--ec-focus-ring-opacity));
}

.focus-ring-dark {
  --ec-focus-ring-color: rgba(var(--ec-dark-rgb), var(--ec-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--ec-border-width) var(--ec-border-style) var(--ec-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--ec-border-width) var(--ec-border-style) var(--ec-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--ec-border-width) var(--ec-border-style) var(--ec-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--ec-border-width) var(--ec-border-style) var(--ec-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--ec-border-width) var(--ec-border-style) var(--ec-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --ec-border-opacity: 1;
  border-color: rgba(var(--ec-primary-rgb), var(--ec-border-opacity)) !important;
}

.border-secondary {
  --ec-border-opacity: 1;
  border-color: rgba(var(--ec-secondary-rgb), var(--ec-border-opacity)) !important;
}

.border-success {
  --ec-border-opacity: 1;
  border-color: rgba(var(--ec-success-rgb), var(--ec-border-opacity)) !important;
}

.border-info {
  --ec-border-opacity: 1;
  border-color: rgba(var(--ec-info-rgb), var(--ec-border-opacity)) !important;
}

.border-warning {
  --ec-border-opacity: 1;
  border-color: rgba(var(--ec-warning-rgb), var(--ec-border-opacity)) !important;
}

.border-danger {
  --ec-border-opacity: 1;
  border-color: rgba(var(--ec-danger-rgb), var(--ec-border-opacity)) !important;
}

.border-light {
  --ec-border-opacity: 1;
  border-color: rgba(var(--ec-light-rgb), var(--ec-border-opacity)) !important;
}

.border-dark {
  --ec-border-opacity: 1;
  border-color: rgba(var(--ec-dark-rgb), var(--ec-border-opacity)) !important;
}

.border-black {
  --ec-border-opacity: 1;
  border-color: rgba(var(--ec-black-rgb), var(--ec-border-opacity)) !important;
}

.border-white {
  --ec-border-opacity: 1;
  border-color: rgba(var(--ec-white-rgb), var(--ec-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--ec-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--ec-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--ec-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--ec-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--ec-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--ec-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--ec-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--ec-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --ec-border-opacity: 0.1;
}

.border-opacity-25 {
  --ec-border-opacity: 0.25;
}

.border-opacity-50 {
  --ec-border-opacity: 0.5;
}

.border-opacity-75 {
  --ec-border-opacity: 0.75;
}

.border-opacity-100 {
  --ec-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--ec-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fs-xl {
  font-size: 1.25rem !important;
}

.fs-lg {
  font-size: 1.125rem !important;
}

.fs-base {
  font-size: 1rem !important;
}

.fs-sm {
  font-size: 0.875rem !important;
}

.fs-xs {
  font-size: 0.75rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: 500 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --ec-text-opacity: 1;
  color: rgba(var(--ec-primary-rgb), var(--ec-text-opacity)) !important;
}

.text-secondary {
  --ec-text-opacity: 1;
  color: rgba(var(--ec-secondary-rgb), var(--ec-text-opacity)) !important;
}

.text-success {
  --ec-text-opacity: 1;
  color: rgba(var(--ec-success-rgb), var(--ec-text-opacity)) !important;
}

.text-info {
  --ec-text-opacity: 1;
  color: rgba(var(--ec-info-rgb), var(--ec-text-opacity)) !important;
}

.text-warning {
  --ec-text-opacity: 1;
  color: rgba(var(--ec-warning-rgb), var(--ec-text-opacity)) !important;
}

.text-danger {
  --ec-text-opacity: 1;
  color: rgba(var(--ec-danger-rgb), var(--ec-text-opacity)) !important;
}

.text-light {
  --ec-text-opacity: 1;
  color: rgba(var(--ec-light-rgb), var(--ec-text-opacity)) !important;
}

.text-dark {
  --ec-text-opacity: 1;
  color: rgba(var(--ec-dark-rgb), var(--ec-text-opacity)) !important;
}

.text-black {
  --ec-text-opacity: 1;
  color: rgba(var(--ec-black-rgb), var(--ec-text-opacity)) !important;
}

.text-white {
  --ec-text-opacity: 1;
  color: rgba(var(--ec-white-rgb), var(--ec-text-opacity)) !important;
}

.text-body {
  --ec-text-opacity: 1;
  color: rgba(var(--ec-body-color-rgb), var(--ec-text-opacity)) !important;
}

.text-muted {
  --ec-text-opacity: 1;
  color: var(--ec-secondary-color) !important;
}

.text-black-50 {
  --ec-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --ec-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --ec-text-opacity: 1;
  color: var(--ec-secondary-color) !important;
}

.text-body-tertiary {
  --ec-text-opacity: 1;
  color: var(--ec-tertiary-color) !important;
}

.text-body-emphasis {
  --ec-text-opacity: 1;
  color: var(--ec-emphasis-color) !important;
}

.text-reset {
  --ec-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --ec-text-opacity: 0.25;
}

.text-opacity-50 {
  --ec-text-opacity: 0.5;
}

.text-opacity-75 {
  --ec-text-opacity: 0.75;
}

.text-opacity-100 {
  --ec-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--ec-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--ec-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--ec-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--ec-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--ec-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--ec-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--ec-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--ec-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --ec-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --ec-link-opacity: 0.1;
}

.link-opacity-25 {
  --ec-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --ec-link-opacity: 0.25;
}

.link-opacity-50 {
  --ec-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --ec-link-opacity: 0.5;
}

.link-opacity-75 {
  --ec-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --ec-link-opacity: 0.75;
}

.link-opacity-100 {
  --ec-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --ec-link-opacity: 1;
}

.bg-primary {
  --ec-bg-opacity: 1;
  background-color: rgba(var(--ec-primary-rgb), var(--ec-bg-opacity)) !important;
}

.bg-secondary {
  --ec-bg-opacity: 1;
  background-color: rgba(var(--ec-secondary-rgb), var(--ec-bg-opacity)) !important;
}

.bg-success {
  --ec-bg-opacity: 1;
  background-color: rgba(var(--ec-success-rgb), var(--ec-bg-opacity)) !important;
}

.bg-info {
  --ec-bg-opacity: 1;
  background-color: rgba(var(--ec-info-rgb), var(--ec-bg-opacity)) !important;
}

.bg-warning {
  --ec-bg-opacity: 1;
  background-color: rgba(var(--ec-warning-rgb), var(--ec-bg-opacity)) !important;
}

.bg-danger {
  --ec-bg-opacity: 1;
  background-color: rgba(var(--ec-danger-rgb), var(--ec-bg-opacity)) !important;
}

.bg-light {
  --ec-bg-opacity: 1;
  background-color: rgba(var(--ec-light-rgb), var(--ec-bg-opacity)) !important;
}

.bg-dark {
  --ec-bg-opacity: 1;
  background-color: rgba(var(--ec-dark-rgb), var(--ec-bg-opacity)) !important;
}

.bg-black {
  --ec-bg-opacity: 1;
  background-color: rgba(var(--ec-black-rgb), var(--ec-bg-opacity)) !important;
}

.bg-white {
  --ec-bg-opacity: 1;
  background-color: rgba(var(--ec-white-rgb), var(--ec-bg-opacity)) !important;
}

.bg-body {
  --ec-bg-opacity: 1;
  background-color: rgba(var(--ec-body-bg-rgb), var(--ec-bg-opacity)) !important;
}

.bg-transparent {
  --ec-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --ec-bg-opacity: 1;
  background-color: rgba(var(--ec-secondary-bg-rgb), var(--ec-bg-opacity)) !important;
}

.bg-body-tertiary {
  --ec-bg-opacity: 1;
  background-color: rgba(var(--ec-tertiary-bg-rgb), var(--ec-bg-opacity)) !important;
}

.bg-opacity-10 {
  --ec-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --ec-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --ec-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --ec-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --ec-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--ec-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--ec-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--ec-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--ec-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--ec-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--ec-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--ec-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--ec-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--ec-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--ec-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--ec-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--ec-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--ec-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--ec-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--ec-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--ec-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--ec-border-radius) !important;
  border-top-right-radius: var(--ec-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--ec-border-radius-sm) !important;
  border-top-right-radius: var(--ec-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--ec-border-radius) !important;
  border-top-right-radius: var(--ec-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--ec-border-radius-lg) !important;
  border-top-right-radius: var(--ec-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--ec-border-radius-xl) !important;
  border-top-right-radius: var(--ec-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--ec-border-radius-xxl) !important;
  border-top-right-radius: var(--ec-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--ec-border-radius-pill) !important;
  border-top-right-radius: var(--ec-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--ec-border-radius) !important;
  border-bottom-right-radius: var(--ec-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--ec-border-radius-sm) !important;
  border-bottom-right-radius: var(--ec-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--ec-border-radius) !important;
  border-bottom-right-radius: var(--ec-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--ec-border-radius-lg) !important;
  border-bottom-right-radius: var(--ec-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--ec-border-radius-xl) !important;
  border-bottom-right-radius: var(--ec-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--ec-border-radius-xxl) !important;
  border-bottom-right-radius: var(--ec-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--ec-border-radius-pill) !important;
  border-bottom-right-radius: var(--ec-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--ec-border-radius) !important;
  border-bottom-left-radius: var(--ec-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--ec-border-radius-sm) !important;
  border-bottom-left-radius: var(--ec-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--ec-border-radius) !important;
  border-bottom-left-radius: var(--ec-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--ec-border-radius-lg) !important;
  border-bottom-left-radius: var(--ec-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--ec-border-radius-xl) !important;
  border-bottom-left-radius: var(--ec-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--ec-border-radius-xxl) !important;
  border-bottom-left-radius: var(--ec-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--ec-border-radius-pill) !important;
  border-bottom-left-radius: var(--ec-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--ec-border-radius) !important;
  border-top-left-radius: var(--ec-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--ec-border-radius-sm) !important;
  border-top-left-radius: var(--ec-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--ec-border-radius) !important;
  border-top-left-radius: var(--ec-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--ec-border-radius-lg) !important;
  border-top-left-radius: var(--ec-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--ec-border-radius-xl) !important;
  border-top-left-radius: var(--ec-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--ec-border-radius-xxl) !important;
  border-top-left-radius: var(--ec-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--ec-border-radius-pill) !important;
  border-top-left-radius: var(--ec-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.z-sticky {
  z-index: 1020 !important;
}

.z-fixed {
  z-index: 1030 !important;
}

@media (min-width: 500px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .w-sm-25 {
    width: 25% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .w-md-25 {
    width: 25% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .me-md-n3 {
    margin-right: -1rem !important;
  }
  .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .me-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .w-lg-25 {
    width: 25% !important;
  }
  .w-lg-50 {
    width: 50% !important;
  }
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .w-xl-25 {
    width: 25% !important;
  }
  .w-xl-50 {
    width: 50% !important;
  }
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .w-xxl-25 {
    width: 25% !important;
  }
  .w-xxl-50 {
    width: 50% !important;
  }
  .w-xxl-100 {
    width: 100% !important;
  }
  .w-xxl-auto {
    width: auto !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2.25rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
:root,
[data-bs-theme=light] {
  --ec-component-color: #020617;
  --ec-component-hover-color: #020617;
  --ec-component-hover-bg: #f1f5f9;
  --ec-component-active-color: #020617;
  --ec-component-active-bg: #f1f5f9;
  --ec-component-disabled-color: #f8fafc;
  --ec-user-selection-color: rgba(2, 6, 23, 0.1);
  --ec-border-radius-xs: calc(var(--ec-border-radius) * 0.5);
  --ec-underline-thickness: 1px;
  --plyr-color-main: #ff4e02;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html *:focus-visible {
  outline: var(--ec-focus-ring-width) solid var(--ec-focus-ring-color);
}

.offcanvas:focus-visible {
  outline: none;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrapper {
  flex: 1;
}

.footer {
  flex-shrink: 0;
}

img,
figure {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

svg {
  max-width: 100%;
}

iframe {
  width: 100%;
}

/* stylelint-disable selector-no-qualifying-type, declaration-no-important */
a,
button {
  text-underline-offset: 0.25em;
}
a.text-decoration-underline:hover,
button.text-decoration-underline:hover {
  text-decoration: none !important;
}

/* stylelint-enable selector-no-qualifying-type, declaration-no-important */
.link-body-emphasis {
  --ec-link-opacity: 1;
}

::selection {
  background: var(--ec-user-selection-color);
}

ol,
ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-left: 1.5rem;
}
ol ul,
ol ol,
ul ul,
ul ol {
  margin-top: 0.5rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

dt {
  color: var(--ec-heading-color);
}

dd {
  margin-bottom: 0.75rem;
}

legend {
  color: var(--ec-heading-color);
}

[class*=" gi-"],
[class^=gi-] {
  display: inline-flex;
}

.topbar {
  position: relative;
  z-index: 1031;
}

.navbar.sticky-top {
  z-index: 1030;
}

.container-start {
  margin-left: calc((100% - (1328px - 1.5rem)) / 2);
}

.container-end {
  margin-right: calc((100% - (1328px - 1.5rem)) / 2);
}

@media (max-width: 1399.98px) {
  .container-start {
    margin-left: calc((100% - (95% - 1.5rem)) / 2);
  }
  .container-end {
    margin-right: calc((100% - (95% - 1.5rem)) / 2);
  }
}
@media (max-width: 499.98px) {
  .container-start,
  .container-end {
    width: 100%;
    padding: 0 0.75rem;
    margin-right: auto;
    margin-left: auto;
  }
}
/* stylelint-disable @stylistic/selector-list-comma-newline-after */
h1, .h1 {
  line-height: 1.25;
}

h2, .h2 {
  line-height: 1.3;
}

h3, .h3 {
  line-height: 1.35;
}

h4, .h4 {
  line-height: 1.4;
}

h5, .h5 {
  line-height: 1.4;
}

h6, .h6 {
  line-height: 1.4;
}

@media (max-width: 767.98px) {
  .display-1 {
    font-size: calc(1.45rem + 2.4vw);
  }
}
@media (max-width: 767.98px) and (min-width: 1200px) {
  .display-1 {
    font-size: 3.25rem;
  }
}

@media (max-width: 767.98px) {
  .display-2 {
    font-size: calc(1.4625rem + 2.55vw);
  }
}
@media (max-width: 767.98px) and (min-width: 1200px) {
  .display-2 {
    font-size: 3.375rem;
  }
}

@media (max-width: 767.98px) {
  .display-3 {
    font-size: calc(1.465rem + 2.58vw);
  }
}
@media (max-width: 767.98px) and (min-width: 1200px) {
  .display-3 {
    font-size: 3.4rem;
  }
}

@media (max-width: 767.98px) {
  .display-4 {
    font-size: calc(1.4225rem + 2.07vw);
  }
}
@media (max-width: 767.98px) and (min-width: 1200px) {
  .display-4 {
    font-size: 2.975rem;
  }
}

h1 a, .h1 a, h2 a, .h2 a, h3 a, .h3 a,
h4 a, .h4 a, h5 a, .h5 a, h6 a, .h6 a {
  color: var(--ec-heading-color);
  text-decoration: none;
}

/* stylelint-enable @stylistic/selector-list-comma-newline-after */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn:focus-visible {
  box-shadow: 0 0 0 0.25rem var(--ec-focus-ring-color);
}

.btn-primary {
  --ec-btn-hover-bg: rgb(221.3, 66.4774703557, 0);
  --ec-btn-active-bg: rgb(221.3, 66.4774703557, 0);
  --ec-btn-hover-border-color: rgb(221.3, 66.4774703557, 0);
  --ec-btn-active-border-color: rgb(221.3, 66.4774703557, 0);
}

.btn-success {
  --ec-btn-hover-bg: rgb(58.1317073171, 152.1682926829, 116.2634146341);
  --ec-btn-active-bg: rgb(58.1317073171, 152.1682926829, 116.2634146341);
  --ec-btn-hover-border-color: rgb(58.1317073171, 152.1682926829, 116.2634146341);
  --ec-btn-active-border-color: rgb(58.1317073171, 152.1682926829, 116.2634146341);
}

.btn-info {
  --ec-btn-hover-bg: rgb(58.1317073171, 141.0548780488, 152.1682926829);
  --ec-btn-active-bg: rgb(58.1317073171, 141.0548780488, 152.1682926829);
  --ec-btn-hover-border-color: rgb(58.1317073171, 141.0548780488, 152.1682926829);
  --ec-btn-active-border-color: rgb(58.1317073171, 141.0548780488, 152.1682926829);
}

.btn-warning {
  --ec-btn-hover-bg: rgb(235.3617834395, 164.1732484076, 81.9382165605);
  --ec-btn-active-bg: rgb(235.3617834395, 164.1732484076, 81.9382165605);
  --ec-btn-hover-border-color: rgb(235.3617834395, 164.1732484076, 81.9382165605);
  --ec-btn-active-border-color: rgb(235.3617834395, 164.1732484076, 81.9382165605);
}

.btn-danger {
  --ec-btn-hover-bg: rgb(232.3135869565, 57.9864130435, 57.9864130435);
  --ec-btn-active-bg: rgb(232.3135869565, 57.9864130435, 57.9864130435);
  --ec-btn-hover-border-color: rgb(232.3135869565, 57.9864130435, 57.9864130435);
  --ec-btn-active-border-color: rgb(232.3135869565, 57.9864130435, 57.9864130435);
}

.btn-secondary {
  --ec-btn-color: #475569;
  --ec-btn-hover-color: #020617;
  --ec-btn-active-color: #020617;
  --ec-btn-focus-color: #020617;
  --ec-btn-disabled-color: #475569;
  --ec-btn-border-color: #e2e8f0;
  --ec-btn-hover-border-color: #e2e8f0;
  --ec-btn-active-border-color: #e2e8f0;
  --ec-btn-disabled-border-color: #f1f5f9;
  --ec-btn-bg: #f1f5f9;
  --ec-btn-hover-bg: #e2e8f0;
  --ec-btn-active-bg: #e2e8f0;
  --ec-btn-focus-bg: #e2e8f0;
  --ec-btn-disabled-bg: #f8fafc;
}

.btn-light {
  --ec-btn-color: #334155;
  --ec-btn-hover-color: #020617;
  --ec-btn-focus-color: #020617;
  --ec-btn-active-color: #020617;
}

.btn-outline-secondary {
  --ec-btn-border-color: #cbd5e1;
  --ec-btn-color: #334155;
  --ec-btn-bg: transparent;
  --ec-btn-hover-border-color: #475569;
  --ec-btn-hover-bg: transparent;
  --ec-btn-active-border-color: #475569;
  --ec-btn-active-bg: transparent;
}

.btn-link {
  --ec-btn-link-decoration: none;
  --ec-btn-link-hover-decoration: none;
  --ec-btn-color: #ff4e02;
  --ec-btn-hover-color: #ff4e02;
  --ec-btn-active-color: #ff4e02;
  --ec-btn-font-weight: 500;
  text-decoration: var(--ec-btn-link-decoration);
  text-underline-offset: 0.25em;
}
.btn-link:hover {
  text-decoration: var(--ec-btn-link-hover-decoration);
}

.btn-icon {
  --ec-btn-size: 2.75rem;
  --ec-btn-font-size: 1rem;
  position: relative;
  flex-shrink: 0;
  width: var(--ec-btn-size);
  height: var(--ec-btn-size);
  padding: initial;
}
.btn-icon.btn-lg, .btn-group-lg > .btn-icon.btn {
  --ec-btn-size: 3.25rem;
  --ec-btn-font-size: 1.25rem;
}
.btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
  --ec-btn-size: 2.125rem;
  --ec-btn-font-size: 1rem;
}

.btn-scroll-top {
  --ec-btn-scroll-top-size: 2.75rem;
  --ec-btn-scroll-top-border-radius: 50%;
  --ec-btn-scroll-top-color: #fff;
  --ec-btn-scroll-top-hover-color: #fff;
  --ec-btn-scroll-top-bg: rgba(2, 6, 23, 0.25);
  --ec-btn-scroll-top-hover-bg: rgba(2, 6, 23, 0.5);
  --ec-btn-scroll-top-icon-size: 1.5rem;
  position: fixed;
  right: 1.25rem;
  bottom: -4.125rem;
  z-index: 1030;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--ec-btn-scroll-top-size);
  height: var(--ec-btn-scroll-top-size);
  color: var(--ec-btn-scroll-top-color);
  text-decoration: none;
  background-color: var(--ec-btn-scroll-top-bg);
  border-radius: var(--ec-btn-scroll-top-border-radius);
  opacity: 0;
  transition: bottom 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55), opacity 0.3s, background-color 0.25s ease-in-out;
}
.btn-scroll-top > .btn-scroll-top-icon {
  font-size: var(--ec-btn-scroll-top-icon-size);
  font-weight: bold;
}
.btn-scroll-top .btn-scroll-top-tooltip {
  position: absolute;
  top: 50%;
  right: 100%;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  transform: translateY(-50%);
}
.btn-scroll-top:hover {
  color: var(--ec-btn-scroll-top-hover-color);
  background-color: var(--ec-btn-scroll-top-hover-bg);
}
.btn-scroll-top:hover .btn-scroll-top-tooltip {
  visibility: visible;
  opacity: 1;
}
.btn-scroll-top.show {
  bottom: 5.25rem;
  opacity: 1;
}
@media (max-width: 499.98px) {
  .btn-scroll-top {
    right: 1rem;
    width: calc(var(--ec-btn-scroll-top-size) * 0.8);
    height: calc(var(--ec-btn-scroll-top-size) * 0.8);
  }
  .btn-scroll-top.show {
    bottom: 4.5rem;
  }
}

.dropdown-toggle {
  display: flex;
  align-items: center;
}
.dropdown-toggle::after {
  flex-shrink: 0;
  width: 1.275em;
  height: 1.275em;
  margin-right: -0.25rem;
  content: "";
  background-color: currentcolor;
  border: 0 !important;
  -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M8.7,13.7c0.4-0.4,1.1-0.4,1.5,0l6,6l6-6c0.4-0.4,1.1-0.4,1.5,0s0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0l-6.7-6.7C8.3,14.6,8.3,14.1,8.7,13.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M8.7,13.7c0.4-0.4,1.1-0.4,1.5,0l6,6l6-6c0.4-0.4,1.1-0.4,1.5,0s0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0l-6.7-6.7C8.3,14.6,8.3,14.1,8.7,13.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
}

.dropup .dropdown-toggle::after {
  -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M15.3,13.3c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6-6l-6,6c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5L15.3,13.3z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M15.3,13.3c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6-6l-6,6c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5L15.3,13.3z'/%3e%3c/svg%3e") no-repeat 50% 50%;
}

.dropend .dropdown-toggle::after {
  -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M12.7,9.7c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5l6-6l-6-6C12.3,10.6,12.3,10.1,12.7,9.7L12.7,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M12.7,9.7c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7c0.4,0.4,0.4,1.1,0,1.5l-6.7,6.7c-0.4,0.4-1.1,0.4-1.5,0s-0.4-1.1,0-1.5l6-6l-6-6C12.3,10.6,12.3,10.1,12.7,9.7L12.7,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
}

.dropstart .dropdown-toggle::before {
  flex-shrink: 0;
  width: 1.275em;
  height: 1.275em;
  margin-left: -0.25rem;
  content: "";
  background-color: currentcolor;
  border: 0 !important;
  -webkit-mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M19.3,9.7c0.4,0.4,0.4,1.1,0,1.5L13.5,17l6,6c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6.7-6.7c-0.4-0.4-0.4-1.1,0-1.5L18,9.7C18.4,9.3,18.9,9.3,19.3,9.7L19.3,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  mask: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3e%3cpath d='M19.3,9.7c0.4,0.4,0.4,1.1,0,1.5L13.5,17l6,6c0.4,0.4,0.4,1.1,0,1.5s-1.1,0.4-1.5,0l-6.7-6.7c-0.4-0.4-0.4-1.1,0-1.5L18,9.7C18.4,9.3,18.9,9.3,19.3,9.7L19.3,9.7z'/%3e%3c/svg%3e") no-repeat 50% 50%;
  -webkit-mask-size: cover;
  mask-size: cover;
}

.dropdown-toggle-split::after, .dropdown-toggle-split::before {
  margin: 0 -0.25rem;
}

.dropdown-menu {
  --ec-dropdown-item-border-radius: calc(var(--ec-border-radius) * 0.75);
  --ec-dropdown-item-spacer: 0.125rem;
  padding-bottom: calc(var(--ec-dropdown-padding-y) - var(--ec-dropdown-item-spacer));
}
.dropdown-menu.show {
  animation: fade-in 0.15s ease-in-out;
}
.dropdown-menu::before, .dropdown-menu::after {
  position: absolute;
  display: block;
  content: "";
}

.dropdown .dropdown-menu,
.dropup .dropdown-menu {
  margin-top: var(--ec-dropdown-spacer) !important;
  margin-bottom: var(--ec-dropdown-spacer) !important;
}
.dropdown .dropdown-menu::before, .dropdown .dropdown-menu::after,
.dropup .dropdown-menu::before,
.dropup .dropdown-menu::after {
  left: 0;
  width: 100%;
  height: calc(var(--ec-dropdown-spacer) * 1.5);
}
.dropdown .dropdown-menu::before,
.dropup .dropdown-menu::before {
  bottom: 100%;
}
.dropdown .dropdown-menu::after,
.dropup .dropdown-menu::after {
  top: 100%;
}

.dropstart .dropdown-menu,
.dropend .dropdown-menu {
  margin-right: var(--ec-dropdown-spacer) !important;
  margin-left: var(--ec-dropdown-spacer) !important;
}
.dropstart .dropdown-menu::before, .dropstart .dropdown-menu::after,
.dropend .dropdown-menu::before,
.dropend .dropdown-menu::after {
  top: 0;
  width: calc(var(--ec-dropdown-spacer) * 2);
  height: 100%;
}
.dropstart .dropdown-menu::before,
.dropend .dropdown-menu::before {
  left: calc(var(--ec-dropdown-spacer) * -2);
}
.dropstart .dropdown-menu::after,
.dropend .dropdown-menu::after {
  left: 100%;
}

.dropdown-item {
  display: flex;
  align-items: center;
  margin-bottom: var(--ec-dropdown-item-spacer);
  border-radius: var(--ec-dropdown-item-border-radius);
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.dropdown-item.show, [data-bs-toggle].show > .dropdown-item, [data-bs-toggle]:hover > .dropdown-item, .dropdown-item:active {
  color: var(--ec-dropdown-link-hover-color);
  background-color: var(--ec-dropdown-link-hover-bg);
}
.dropdown-item:focus-visible {
  outline: none;
  box-shadow: 0 0 0 0.25rem var(--ec-focus-ring-color);
}
.dropdown-item.dropdown-toggle::after {
  margin-left: auto;
}
.dropdown-item .item-active-indicator {
  display: none;
}
.dropdown-item.active .item-active-indicator {
  display: flex;
  padding-left: 0.5rem;
}

.nav {
  --ec-nav-link-line-height: 1.375rem;
  --ec-nav-link-active-color: var(--ec-component-active-color);
  flex-direction: row;
  gap: 0;
}

.nav-link {
  display: flex;
  align-items: center;
}
.nav-link.show {
  color: var(--ec-nav-link-hover-color);
}
.nav-link.active {
  color: var(--ec-nav-link-active-color);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--ec-nav-link-disabled-color);
}

.nav-tabs {
  --ec-nav-tabs-gap: 0.5rem;
  --ec-nav-tabs-bg: transparent;
  --ec-nav-tabs-link-hover-bg: transparent;
  --ec-nav-tabs-link-hover-color: #ff4e02;
  --ec-nav-tabs-link-hover-border-color: transparent;
  --ec-nav-tabs-link-active-color: #ff4e02;
  --ec-nav-tabs-link-active-box-shadow: none;
  gap: var(--ec-nav-tabs-gap);
  background-color: var(--ec-nav-tabs-bg);
}
.nav-tabs .nav-link {
  line-height: var(--ec-nav-link-line-height);
  border-radius: var(--ec-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover:not(.active) {
  color: var(--ec-nav-tabs-link-hover-color);
  border-color: var(--ec-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link.show, .nav-tabs .nav-link:focus-visible {
  background-color: var(--ec-nav-tabs-link-hover-bg);
}
.nav-tabs .nav-link:focus-visible {
  box-shadow: 0 0 0 0.25rem var(--ec-focus-ring-color) !important;
}
.nav-tabs .nav-link.active {
  color: var(--ec-nav-tabs-link-active-color);
  background-color: var(--ec-nav-tabs-link-active-bg);
  box-shadow: var(--ec-nav-tabs-link-active-box-shadow);
}
.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  background: none;
}
.nav-tabs.flex-column .nav-link {
  justify-content: start;
}
.nav-tabs .dropdown-menu {
  border-radius: var(--ec-dropdown-border-radius);
}

.nav-pills {
  --ec-nav-pills-gap: 0.5rem;
  --ec-nav-pills-link-border-width: var(--ec-border-width);
  --ec-nav-pills-link-border-color: transparent;
  --ec-nav-pills-link-hover-color: var(--ec-component-hover-color);
  --ec-nav-pills-link-hover-bg: var(--ec-component-hover-bg);
  --ec-nav-pills-link-hover-border-color: var(--ec-component-hover-bg);
  --ec-nav-pills-link-active-border-color: var(--ec-component-active-bg);
  --ec-nav-pills-link-disabled-color: var(--ec-component-disabled-color);
  --ec-nav-pills-link-disabled-border-color: transparent;
  gap: var(--ec-nav-pills-gap);
}
.nav-pills .nav-link {
  line-height: var(--ec-nav-link-line-height);
  border: var(--ec-nav-pills-link-border-width) solid var(--ec-nav-pills-link-border-color);
}
.nav-pills .nav-link:hover, .nav-pills .nav-link.show, .nav-pills .nav-link:focus-visible {
  color: var(--ec-nav-pills-link-hover-color);
  background-color: var(--ec-nav-pills-link-hover-bg);
  border-color: var(--ec-nav-pills-link-hover-border-color);
}
.nav-pills .nav-link.active {
  border-color: var(--ec-nav-pills-link-active-border-color);
}
.nav-pills .nav-link.disabled, .nav-pills .nav-link:disabled {
  color: var(--ec-nav-pills-link-disabled-color);
  border-color: var(--ec-nav-pills-link-disabled-border-color);
}

.nav-fill:not(.flex-column) .nav-link,
.nav-justified:not(.flex-column) .nav-link {
  justify-content: center;
}

.nav.flex-column .nav-link {
  display: inline-flex;
}

.page-nav {
  --ec-nav-link-padding-x: 1rem;
  --ec-nav-link-active-color: #ff4e02;
}
.page-nav .nav-link {
  position: relative;
}
.page-nav .nav-link::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 2px;
  height: 100%;
  content: "";
  background-color: transparent;
  transition: background-color 0.25s ease-in-out;
}
.page-nav .nav-link.active::before {
  background-color: #ff4e02;
}

.navbar {
  --ec-navbar-brand-font-weight: 600;
}
@media (max-width: 991.98px) {
  .navbar {
    --ec-navbar-padding-y: 0.5rem;
  }
}

.navbar-toggler {
  position: relative;
  width: 1.875rem;
  height: 2.25rem;
}

.navbar-toggler-icon {
  top: 50%;
  display: block;
  margin-top: -0.0625rem;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 0.075s;
}
.navbar-toggler-icon, .navbar-toggler-icon::before, .navbar-toggler-icon::after {
  position: absolute;
  width: 1.375rem;
  height: 0.125rem;
  background-color: var(--ec-navbar-color);
  transition-property: transform;
}
.navbar-toggler-icon::before, .navbar-toggler-icon::after {
  display: block;
  content: "";
}
.navbar-toggler-icon::before {
  top: -0.4375rem;
  transition: top 0.075s 0.12s ease, opacity 0.075s ease, width 0.15s ease-in-out;
}
.navbar-toggler-icon::after {
  right: 0;
  bottom: -0.4375rem;
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 0.15s ease-in-out;
}

.navbar-toggler:not(.active):not([aria-expanded=true]):hover .navbar-toggler-icon::before, .navbar-toggler:not(.active):not([aria-expanded=true]):hover .navbar-toggler-icon::after {
  width: 1.03125rem;
}

.navbar-toggler.active .navbar-toggler-icon,
[aria-expanded=true] .navbar-toggler-icon {
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: rotate(45deg);
}
.navbar-toggler.active .navbar-toggler-icon::before, .navbar-toggler.active .navbar-toggler-icon::after,
[aria-expanded=true] .navbar-toggler-icon::before,
[aria-expanded=true] .navbar-toggler-icon::after {
  width: 1.375rem;
}
.navbar-toggler.active .navbar-toggler-icon::before,
[aria-expanded=true] .navbar-toggler-icon::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.navbar-toggler.active .navbar-toggler-icon::after,
[aria-expanded=true] .navbar-toggler-icon::after {
  bottom: 0;
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: rotate(-90deg);
}

.dropdown-megamenu .dropdown-toggle {
  position: relative;
}
.dropdown-megamenu .dropdown-toggle::before {
  position: absolute;
  right: 0;
  bottom: -8px;
  left: 0;
  display: none;
  width: 100%;
  height: 8px;
  content: "";
}
.dropdown-megamenu .dropdown-toggle.show::before {
  display: block;
}
@media (min-width: 992px) {
  .dropdown-megamenu .dropdown-toggle::after {
    display: none;
  }
}
.dropdown-megamenu .dropdown-menu {
  --ec-dropdown-padding-y: 2.5rem;
  --ec-dropdown-padding-x: 2.5rem;
  --ec-dropdown-spacer: 0;
  --ec-dropdown-box-shadow: 0 1.25rem 2.5rem 0 rgba(59, 60, 66, .03);
  --ec-dropdown-border-radius: calc(var(--ec-border-radius) * 2);
  left: 50%;
  width: 100%;
  text-wrap: wrap;
  transform: translateX(-50%);
  animation: initial;
}
@media (max-width: 991.98px) {
  .dropdown-megamenu .dropdown-menu {
    --ec-dropdown-padding-y: initial;
    --ec-dropdown-spacer: 0.5rem;
    --ec-dropdown-box-shadow: initial;
    --ec-dropdown-bg: transparent;
    left: 0;
    margin-bottom: 1rem;
    transform: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-nav {
    --ec-nav-link-padding-x: 1rem;
  }
}

.card-hover {
  transition: box-shadow 0.25s ease-in-out;
}
.card-hover:hover {
  box-shadow: 0 0.5rem 1.5rem 0 rgba(50, 73, 96, 0.06);
}

.card-feature {
  overflow: hidden;
}
.card-feature .card-title,
.card-feature .card-text,
.card-feature .card-text-overlay {
  transition: opacity 0.25s ease-in-out;
}
.card-feature .card-title {
  margin-bottom: 0;
}
.card-feature .card-text-content {
  font-size: 0.875rem;
}
.card-feature .card-text-overlay {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  display: none;
  height: 3.5rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0, #fff 85%);
}
@media (max-width: 499.98px) {
  .card-feature {
    --ec-card-spacer-y: 1rem;
    --ec-card-spacer-x: 1rem;
  }
}
@media (min-width: 500px) {
  .card-feature .card-title {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1.5rem 1.5rem;
    margin-bottom: 0;
  }
  .card-feature .card-text {
    opacity: 0;
  }
  .card-feature .card-text-content {
    position: relative;
    height: 12.625rem;
    padding-right: 0.75rem;
  }
  .card-feature .card-text-overlay {
    display: block;
  }
  .card-feature .scrolled-to-end + .card-text-overlay {
    opacity: 0;
  }
  .card-feature:hover .card-title {
    opacity: 0;
  }
  .card-feature:hover .card-text {
    opacity: 1;
  }
}

.accordion {
  --ec-accordion-btn-font-size: 1rem;
  --ec-accordion-btn-font-weight: 500;
  --ec-accordion-body-font-size: 1rem;
}

.accordion-button {
  font-size: var(--ec-accordion-btn-font-size);
  font-weight: var(--ec-accordion-btn-font-weight);
  transition: color 0.25s ease-in-out;
}
.accordion-button:focus-visible {
  box-shadow: 0 0 0 0.25rem var(--ec-focus-ring-color);
}
.accordion-button:not(.collapsed) {
  box-shadow: none;
}
.accordion-button:hover {
  color: var(--ec-heading-color);
}

.accordion-item {
  border: 0;
  border-bottom: var(--ec-accordion-border-width) solid var(--ec-accordion-border-color);
}

.accordion-body {
  padding-bottom: var(--ec-accordion-btn-padding-y);
  font-size: var(--ec-accordion-body-font-size);
}

.accordion-autoplay {
  --ec-accordion-progress-bar-height: 0.125rem;
  --ec-accordion-progress-bar-bg: #020617;
}
.accordion-autoplay .accordion-item {
  position: relative;
}
.accordion-autoplay .accordion-item:has(.collapsed) .accordion-progress {
  display: none;
}
.accordion-autoplay .accordion-button::after {
  display: none;
}

.accordion-progress {
  position: absolute;
  bottom: calc(var(--ec-border-width) * -1);
  left: 0;
  height: var(--ec-accordion-progress-bar-height);
  background-color: var(--ec-accordion-progress-bar-bg);
  transition: width 0.1s linear;
}

@media (max-width: 499.98px) {
  .accordion-faq .accordion-button {
    gap: 0.5rem;
    font-size: 1rem !important;
  }
}

.collapse-toggle:not(.collapsed) .collapse-icon {
  transform: rotate(180deg);
}

.collapse-icon {
  transition: transform 0.25s ease-in-out;
}

.simplebar-scrollbar::before {
  right: 0;
  left: 0;
  background-color: #94a3b8;
  border-radius: 0.5rem;
}
.simplebar-scrollbar.simplebar-visible::before {
  opacity: 1;
}

.simplebar-track {
  border-radius: 0.5rem;
}
.simplebar-track.simplebar-vertical {
  width: 0.125rem;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 0;
  bottom: 0;
}
.simplebar-track.simplebar-horizontal {
  height: 0.125rem;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  top: 0;
  bottom: 0;
  height: 0.125rem;
  background-color: #94a3b8;
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar.simplebar-visible {
  opacity: 1;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar::before {
  right: 0;
  left: 0;
}

[data-simplebar][data-simplebar-auto-hide=false] .simplebar-track {
  background-color: #f1f5f9;
}

.media-img {
  --ec-media-img-width-desktop: 11.25rem;
  --ec-media-img-width-tablet: 9rem;
  --ec-media-img-width-mobile: 7.75rem;
  display: block;
  width: var(--ec-media-img-width-mobile);
}
@media (min-width: 500px) {
  .media-img {
    width: var(--ec-media-img-width-tablet);
  }
}
@media (min-width: 992px) {
  .media-img {
    width: var(--ec-media-img-width-desktop);
  }
}

.hover-fade-item {
  transition: opacity 0.25s ease-in-out;
}
@media (min-width: 992px) {
  .hover-fade-item {
    opacity: 0;
  }
}

.hover-fade:hover .hover-fade-item {
  opacity: 1;
}

.swiper-pagination-bullets {
  --swiper-pagination-bottom: 1.5rem;
  --swiper-pagination-right: 1.5rem;
  --swiper-pagination-bullet-inactive-color: #cbd5e1;
  --swiper-pagination-color: #ff4e02;
  --swiper-pagination-bullet-border-radius: 50%;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 0.375rem;
  --swiper-pagination-bullet-vertical-gap: 0.375rem;
  --swiper-pagination-bullet-transition: background-color 0.2s ease-in-out;
}

.swiper-pagination-bullet {
  transition: var(--swiper-pagination-bullet-transition);
}

.swiper-load {
  visibility: hidden;
}
.swiper-load.swiper-initialized {
  visibility: visible;
}

.swiper-ticker {
  overflow: hidden;
  pointer-events: none;
}
.swiper-ticker .swiper-wrapper {
  transition-timing-function: linear;
}
.swiper-ticker .swiper-slide {
  width: auto;
}

.steps {
  display: flex;
  padding-top: 1.3125rem;
}

.step {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
  text-decoration: none;
}

.step-progress {
  position: relative;
  width: 100%;
  height: 1px;
}

.step-progress-start,
.step-progress-end {
  position: absolute;
  top: 50%;
  width: 50%;
  height: 0.125rem;
  margin-top: -0.0625rem;
  overflow: hidden;
}
.step-progress-start::before,
.step-progress-end::before {
  display: block;
  width: 62rem;
  height: 100%;
  background-color: #e2e8f0;
  content: "";
}

.step-progress-start {
  left: 0;
}

.step-progress-end {
  left: 50%;
}

.steps:not(.steps-vertical) .step:first-child .step-progress .step-progress-start {
  display: none;
}
.steps:not(.steps-vertical) .step:last-child .step-progress .step-progress-end {
  display: none;
}

.step-number {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  width: 2.625rem;
  height: 2.625rem;
  margin-top: -1.3125rem;
  margin-left: -1.3125rem;
  transition: background-color 0.25s ease-in-out, color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  border-radius: 50%;
  background: #fff;
  color: #020617;
  font-size: 0.875rem;
  font-weight: 700;
  box-shadow: none;
  line-height: 2.625rem;
}

.step-label {
  padding-top: 1.8125rem;
  color: #020617;
  font-size: 0.75rem;
}

.step.active .step-progress .step-progress-start,
.step.active .step-progress .step-progress-end {
  height: 1px;
  background-color: #020617;
}
.step.active .step-progress .step-progress-start::before,
.step.active .step-progress .step-progress-end::before {
  display: none;
}
.step.active .step-number {
  background-color: #fff;
  color: #020617;
  box-shadow: none;
}

.steps.steps-vertical {
  flex-direction: column;
}
.steps.steps-vertical .step {
  display: flex;
}
.steps.steps-vertical .step.active .step-progress .step-progress-end {
  width: 1px;
  height: 100%;
}
.steps.steps-vertical .step:last-child .step-label {
  margin-bottom: initial;
}
.steps.steps-vertical .step-progress {
  flex-shrink: 0;
  width: 2.625rem;
  height: auto;
}
.steps.steps-vertical .step-progress-start {
  display: none;
}
.steps.steps-vertical .step-progress-end {
  position: absolute;
  top: 0;
  left: 50%;
  width: 0.125rem;
  height: 100%;
  transform: translateX(-50%);
}
.steps.steps-vertical .step-progress-end::before {
  width: 100%;
  height: 100%;
  background-color: #e2e8f0;
}
.steps.steps-vertical .step-number {
  position: relative;
  top: initial;
  left: initial;
  margin-top: -0.125rem;
  margin-left: initial;
}
.steps.steps-vertical .step-label {
  margin-top: -0.125rem;
  margin-bottom: 1.5rem;
  margin-left: 3.5rem;
  padding-top: initial;
  font-size: 1rem;
  text-align: left;
}
@media (max-width: 767.98px) {
  .steps.steps-vertical .step-label {
    margin-left: 1rem;
  }
}

a.step:hover .step-number:not(.active) {
  background-color: #fff;
  color: #020617;
  box-shadow: none;
}
a.step.active {
  pointer-events: none;
}

.parallax {
  position: relative;
}

.parallax-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.parallax-layer:first-child {
  position: relative;
}
.parallax-layer > img {
  display: block;
  width: 100%;
}

.binded-content {
  position: relative;
}

.binded-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: opacity, transform;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: scale(0.95);
  opacity: 0;
  pointer-events: none;
  cursor: default;
}
.binded-item.active {
  position: relative;
  transform: none;
  opacity: 1;
  z-index: 5;
  cursor: initial;
  pointer-events: initial;
}

.pagination {
  --ec-pagination-spacer: 0.5rem;
  --ec-pagination-size: 2.75rem;
  --ec-pagination-font-weight: 500;
  flex-direction: row;
  gap: var(--ec-pagination-spacer);
  margin-bottom: 0;
}

.page-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--ec-pagination-size);
  height: var(--ec-pagination-size);
  font-weight: var(--ec-pagination-font-weight);
}

.pagination-sm {
  --ec-pagination-size: 2.125rem;
}

.pagination-lg {
  --ec-pagination-size: 3.25rem;
}

/*# sourceMappingURL=styles.css.map */
