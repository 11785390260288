// Extend default Bootstrap's card compopnent


// Hover card

.card-hover {
  transition: box-shadow .25s ease-in-out;

  &:hover {
    box-shadow: $box-shadow;
  }
}


// Feature card

.card-feature {
  overflow: hidden;

  .card-title,
  .card-text,
  .card-text-overlay {
    transition: opacity .25s ease-in-out;
  }

  .card-title {
    margin-bottom: 0;
  }

  .card-text-content {
    font-size: $font-size-sm;
  }

  .card-text-overlay {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    display: none;
    height: 3.5rem;
    background: linear-gradient(180deg, rgba($white, 0) 0, $white 85%);
  }

  @include media-breakpoint-down(sm) {
    --#{$prefix}card-spacer-y: 1rem;
    --#{$prefix}card-spacer-x: 1rem;
  }

  @include media-breakpoint-up(sm) {
    .card-title {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: $card-spacer-y $card-spacer-x;
      margin-bottom: 0;
    }

    .card-text {
      opacity: 0;
    }

    .card-text-content {
      position: relative;
      height: 12.625rem;
      padding-right: $card-spacer-x * .5;
    }

    .card-text-overlay {
      display: block;
    }

    .scrolled-to-end + .card-text-overlay {
      opacity: 0;
    }

    // Hover state
    &:hover {
      .card-title {
        opacity: 0;
      }
      .card-text {
        opacity: 1;
      }
    }
  }
}
