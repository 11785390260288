// Custom scrollbar
// based on https://github.com/Grsmto/simplebar

// stylelint-disable selector-pseudo-element-colon-notation
// Base styles
.simplebar-scrollbar {
  &::before {
    right: 0;
    left: 0;
    background-color: $scrollbar-bg;
    @include border-radius($scrollbar-border-radius);
  }
  &.simplebar-visible::before { opacity: 1; }
}

.simplebar-track {
  @include border-radius($scrollbar-border-radius);

  &.simplebar-vertical {
    width: $scrollbar-width;
    .simplebar-scrollbar:before {
      top: 0;
      bottom: 0;
    }
  }

  &.simplebar-horizontal {
    height: $scrollbar-width;
    .simplebar-scrollbar {
      top: 0;
      bottom: 0;
      height: $scrollbar-width;
      background-color: $scrollbar-bg;
      opacity: 0;
      transition: opacity .25s ease-in-out;
      &.simplebar-visible {
        opacity: 1;
      }
      &::before {
        right: 0;
        left: 0;
      }
    }
  }
}

[data-simplebar] {

  &[data-simplebar-auto-hide="false"] {
    .simplebar-track { background-color: $scrollbar-track-bg; }
  }
}
// stylelint-enable selector-pseudo-element-colon-notation
