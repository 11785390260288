// Override Bootstrap's base button class

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:focus-visible {
    box-shadow: $focus-ring-box-shadow;
  }
}


// Override solid buttons

@each $color, $value in map-remove($theme-colors, "secondary", "dark", "light") {
  .btn-#{$color} {
    --#{$prefix}btn-hover-bg: #{darken($value, 7%)};
    --#{$prefix}btn-active-bg: #{darken($value, 7%)};
    --#{$prefix}btn-hover-border-color: #{darken($value, 7%)};
    --#{$prefix}btn-active-border-color: #{darken($value, 7%)};
  }
}

.btn-secondary {
  --#{$prefix}btn-color: #{$gray-600};
  --#{$prefix}btn-hover-color: #{$gray-900};
  --#{$prefix}btn-active-color: #{$gray-900};
  --#{$prefix}btn-focus-color: #{$gray-900};
  --#{$prefix}btn-disabled-color: #{$gray-600};
  --#{$prefix}btn-border-color: #{$gray-200};
  --#{$prefix}btn-hover-border-color: #{$gray-200};
  --#{$prefix}btn-active-border-color: #{$gray-200};
  --#{$prefix}btn-disabled-border-color: #{$gray-100};
  --#{$prefix}btn-bg: #{$gray-100};
  --#{$prefix}btn-hover-bg: #{$gray-200};
  --#{$prefix}btn-active-bg: #{$gray-200};
  --#{$prefix}btn-focus-bg: #{$gray-200};
  --#{$prefix}btn-disabled-bg: #{$gray-50};
}

.btn-light {
  --#{$prefix}btn-color: #{$gray-700};
  --#{$prefix}btn-hover-color: #{$gray-900};
  --#{$prefix}btn-focus-color: #{$gray-900};
  --#{$prefix}btn-active-color: #{$gray-900};
}


// Override outline buttons

.btn-outline-secondary {
  --#{$prefix}btn-border-color: #{$gray-300};
  --#{$prefix}btn-color: #{$gray-700};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-hover-border-color: #{$gray-600};
  --#{$prefix}btn-hover-bg: transparent;
  --#{$prefix}btn-active-border-color: #{$gray-600};
  --#{$prefix}btn-active-bg: transparent;
}


// Override link button

.btn-link {
  --#{$prefix}btn-link-decoration: #{$btn-link-decoration};
  --#{$prefix}btn-link-hover-decoration: #{$btn-link-hover-decoration};
  --#{$prefix}btn-color: #{$primary};
  --#{$prefix}btn-hover-color: #{$primary};
  --#{$prefix}btn-active-color: #{$primary};
  --#{$prefix}btn-font-weight: #{$btn-font-weight};

  text-decoration: var(--#{$prefix}btn-link-decoration);
  text-underline-offset: .25em;

  &:hover {
    text-decoration: var(--#{$prefix}btn-link-hover-decoration);
  }
}


// Icon button

.btn-icon {
  --#{$prefix}btn-size: #{$btn-icon-size};
  --#{$prefix}btn-font-size: #{$btn-icon-font-size};

  position: relative;
  flex-shrink: 0;
  width: var(--#{$prefix}btn-size);
  height: var(--#{$prefix}btn-size);
  padding: initial;

  // Sizing
  &.btn-lg {
    --#{$prefix}btn-size: #{$btn-icon-size-lg};
    --#{$prefix}btn-font-size: #{$btn-icon-font-size-lg};
  }
  &.btn-sm {
    --#{$prefix}btn-size: #{$btn-icon-size-sm};
    --#{$prefix}btn-font-size: #{$btn-icon-font-size-sm};
  }
}


// Scroll to top button

.btn-scroll-top {
  --#{$prefix}btn-scroll-top-size: #{$btn-scroll-top-size};
  --#{$prefix}btn-scroll-top-border-radius: 50%;
  --#{$prefix}btn-scroll-top-color: #{$btn-scroll-top-color};
  --#{$prefix}btn-scroll-top-hover-color: #{$btn-scroll-top-hover-color};
  --#{$prefix}btn-scroll-top-bg: #{$btn-scroll-top-bg};
  --#{$prefix}btn-scroll-top-hover-bg: #{$btn-scroll-top-hover-bg};
  --#{$prefix}btn-scroll-top-icon-size: #{$btn-scroll-top-icon-font-size};

  position: fixed;
  right: $spacer * 1.25;
  bottom: -($btn-scroll-top-size * 1.5);
  z-index: $zindex-fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--#{$prefix}btn-scroll-top-size);
  height: var(--#{$prefix}btn-scroll-top-size);
  color: var(--#{$prefix}btn-scroll-top-color);
  text-decoration: none;
  background-color: var(--#{$prefix}btn-scroll-top-bg);
  @include border-radius(var(--#{$prefix}btn-scroll-top-border-radius));
  opacity: 0;
  transition: $btn-scroll-top-transition;
  > .btn-scroll-top-icon {
    font: {
      size: var(--#{$prefix}btn-scroll-top-icon-size);
      weight: bold;
    }
  }

  .btn-scroll-top-tooltip {
    position: absolute;
    top: 50%;
    right: 100%;
    visibility: hidden;
    opacity: 0;
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
    transform: translateY(-50%);
  }

  &:hover {
    color: var(--#{$prefix}btn-scroll-top-hover-color);
    background-color: var(--#{$prefix}btn-scroll-top-hover-bg);
    .btn-scroll-top-tooltip {
      visibility: visible;
      opacity: 1;
    }
  }

  &.show {
    bottom: $spacer * 5.25;
    opacity: 1;
  }

  @include media-breakpoint-down(sm) {
    right: $spacer;
    width: calc(var(--#{$prefix}btn-scroll-top-size) * .8);
    height: calc(var(--#{$prefix}btn-scroll-top-size) * .8);
    &.show { bottom: $spacer * 4.5; }
  }
}
