// Override default Bootstrap's accordion component

.accordion {
  --#{$prefix}accordion-btn-font-size: #{$accordion-button-font-size};
  --#{$prefix}accordion-btn-font-weight: #{$accordion-button-font-weight};
  --#{$prefix}accordion-body-font-size: #{$accordion-body-font-size};
}

.accordion-button {
  @include font-size(var(--#{$prefix}accordion-btn-font-size));
  font-weight: var(--#{$prefix}accordion-btn-font-weight);
  transition: color .25s ease-in-out;

  &:focus-visible {
    @include box-shadow($focus-ring-box-shadow);
  }

  &:not(.collapsed) {
    box-shadow: none;
  }

  &:hover {
    color: $accordion-button-hover-color;
  }
}

.accordion-item {
  border: 0;
  border-bottom: var(--#{$prefix}accordion-border-width) solid var(--#{$prefix}accordion-border-color);
}

.accordion-body {
  padding-bottom: var(--#{$prefix}accordion-btn-padding-y);
  @include font-size(var(--#{$prefix}accordion-body-font-size));
}


// Autoplay accordion

.accordion-autoplay {
  --#{$prefix}accordion-progress-bar-height: #{$accordion-progress-bar-height};
  --#{$prefix}accordion-progress-bar-bg: #{$accordion-progress-bar-bg};

  .accordion-item {
    position: relative;

    &:has(.collapsed) {
      .accordion-progress {
        display: none;
      }
    }
  }

  .accordion-button::after {
    display: none;
  }
}

.accordion-progress {
  position: absolute;
  bottom: calc(#{$accordion-border-width} * -1);
  left: 0;
  height: var(--#{$prefix}accordion-progress-bar-height);
  background-color: var(--#{$prefix}accordion-progress-bar-bg);
  transition: width .1s linear;
}


// Accordion faq

.accordion-faq {
  .accordion-button {
    @include media-breakpoint-down(sm) {
      gap: .5rem;
      font-size: $font-size-base !important; // stylelint-disable-line declaration-no-important
    }
  }
}
