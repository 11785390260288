// Add new CSS variables to default Bootstrap's .navbar

.navbar {
  --#{$prefix}navbar-brand-font-weight: #{$navbar-brand-font-weight};

  @include media-breakpoint-down(lg) {
    --#{$prefix}navbar-padding-y: #{$spacer * .5};
  }
}


// Navbar toggler

.navbar-toggler {
  position: relative;
  width: $navbar-toggler-bar-width + $navbar-toggler-padding-x * 2;
  height: $navbar-toggler-bar-height * 3 + $navbar-toggler-bar-spacing * 2 + $navbar-toggler-padding-y * 2;
}

.navbar-toggler-icon {
  top: 50%;
  display: block;
  margin-top: $navbar-toggler-bar-height * -.5;
  transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  transition-duration: .075s;

  &,
  &::before,
  &::after {
    position: absolute;
    width: $navbar-toggler-bar-width;
    height: $navbar-toggler-bar-height;
    background-color: var(--#{$prefix}navbar-color);
    transition-property: transform;
  }
  &::before,
  &::after {
    display: block;
    content: "";
  }
  &::before {
    top: ($navbar-toggler-bar-spacing + $navbar-toggler-bar-height) * -1;
    transition: top .075s .12s ease, opacity .075s ease, width .15s ease-in-out;
  }
  &::after {
    right: 0;
    bottom: ($navbar-toggler-bar-spacing + $navbar-toggler-bar-height) * -1;
    transition: bottom .075s .12s ease, transform .075s cubic-bezier(.55, .055, .675, .19), width .15s ease-in-out;
  }
}

.navbar-toggler:not(.active):not([aria-expanded="true"]):hover .navbar-toggler-icon {
  &::before,
  &::after {
    width: $navbar-toggler-bar-width * .75;
  }
}

.navbar-toggler.active,
[aria-expanded="true"] {
  .navbar-toggler-icon {
    transition-delay: .12s;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: rotate(45deg);
    &::before,
    &::after {
      width: $navbar-toggler-bar-width;
    }
    &::before {
      top: 0;
      opacity: 0;
      transition: top .075s ease, opacity .075s .12s ease;
    }
    &::after {
      bottom: 0;
      transition: bottom .075s ease, transform .075s .12s cubic-bezier(.215, .61, .355, 1);
      transform: rotate(-90deg);
    }
  }
}


// Navbar megamenu

.dropdown-megamenu {
  .dropdown-toggle {
    position: relative;

    &::before {
      position: absolute;
      right: 0;
      bottom: -8px;
      left: 0;
      display: none;
      width: 100%;
      height: 8px;
      content: "";
    }

    &.show::before {
      display: block;
    }

    @include media-breakpoint-up(lg) {
      &::after {
        display: none;
      }
    }
  }

  .dropdown-menu {
    --#{$prefix}dropdown-padding-y: #{$spacer * 2.5};
    --#{$prefix}dropdown-padding-x: #{$spacer * 2.5};
    --#{$prefix}dropdown-spacer: 0;
    --#{$prefix}dropdown-box-shadow: 0 1.25rem 2.5rem 0 rgba(59, 60, 66, .03);
    --#{$prefix}dropdown-border-radius: #{$border-radius-xl};

    left: 50%;
    width: 100%;
    text-wrap: wrap;
    transform: translateX(-50%);
    animation: initial;
  }

  @include media-breakpoint-down(lg) {
    .dropdown-menu {
      --#{$prefix}dropdown-padding-y: initial;
      --#{$prefix}dropdown-spacer: #{$spacer * .5};
      --#{$prefix}dropdown-box-shadow: initial;
      --#{$prefix}dropdown-bg: transparent;

      left: 0;
      margin-bottom: $spacer;
      transform: none;
    }
  }
}


// Navbar nav

.navbar-nav {
  @include media-breakpoint-down(lg) {
    --#{$prefix}nav-link-padding-x: #{$spacer};
  }
}
