// Collapse toggle

.collapse-toggle:not(.collapsed) {
  .collapse-icon {
    transform: rotate(180deg);
  }
}

.collapse-icon {
  transition: transform .25s ease-in-out;
}
