// Cursor parallax
// based on https://github.com/wagerfield/parallax

.parallax {
  position: relative;
}
.parallax-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:first-child { position: relative; }

  > img {
    display: block;
    width: 100%;
  }
}
