// Typography related styles

// Headings
/* stylelint-disable @stylistic/selector-list-comma-newline-after */

h1, .h1 {
  line-height: $h1-line-height;
}

h2, .h2 {
  line-height: $h2-line-height;
}

h3, .h3 {
  line-height: $h3-line-height;
}

h4, .h4 {
  line-height: $h4-line-height;
}

h5, .h5 {
  line-height: $h4-line-height;
}

h6, .h6 {
  line-height: $h4-line-height;
}


// Reducing displays font-size on mobile devices

.display-1 {
  @include media-breakpoint-down(md) {
    @include font-size(map-get($display-font-sizes, 1) * .65);
  }
}

.display-2 {
  @include media-breakpoint-down(md) {
    @include font-size(map-get($display-font-sizes, 2) * .75);
  }
}

.display-3 {
  @include media-breakpoint-down(md) {
    @include font-size(map-get($display-font-sizes, 3) * .85);
  }
}

.display-4 {
  @include media-breakpoint-down(md) {
    @include font-size(map-get($display-font-sizes, 4) * .85);
  }
}


// Link inside headings

h1, .h1, h2, .h2, h3, .h3,
h4, .h4, h5, .h5, h6, .h6 {
  a {
    color: var(--#{$prefix}heading-color);
    text-decoration: none;
  }
}
/* stylelint-enable @stylistic/selector-list-comma-newline-after */
