// Utilities
// Extend / update default Bootstrap's utility classes

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

/* stylelint-disable @stylistic/value-list-max-empty-lines, @stylistic/function-max-empty-lines, declaration-no-important */
$utilities: map-merge(
  $utilities,
  (
    // Remove utilities
    "link-offset": null,
    "link-underline": null,
    "link-underline-opacity": null,

    // Width
    "width": (
      property: width,
      responsive: true,
      class: w,
      values: (
        25: 25%,
        50: 50%,
        100: 100%,
        auto: auto
      )
    ),

    // Text opacity
    "text-opacity": (
      css-var: true,
      class: text-opacity,
      values: (
        25: .25,
        50: .5,
        75: .75,
        100: 1
      )
    ),

    // z-index
    "z-index": (
      property: z-index,
      class: z,
      values: map-merge(
        $zindex-levels,
        (
          sticky: $zindex-sticky,
          fixed: $zindex-fixed
        )
      )
    ),
  )
);


// Fix Safari quirk of rounded corners

[class^="rounded"].overflow-hidden,
[class*=" rounded"].overflow-hidden {
  transform: translateZ(0);
}


// Text truncate

.text-line-clamp {
  --#{$prefix}text-line-clamp-lines: #{$text-line-clamp-lines};
  --#{$prefix}text-line-clamp-height: #{$text-line-clamp-height};

  display: -webkit-box;
  height: var(--#{$prefix}text-line-clamp-height);
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--#{$prefix}text-line-clamp-lines);
}


// Negative gutter

.mx-n-container {
  margin: {
    right: -($container-padding-x * .5) !important;
    left: -($container-padding-x * .5) !important;
  }
}


// Container padding

.px-container {
  padding: {
    right: $container-padding-x * .5 !important;
    left: $container-padding-x * .5 !important;
  }
}


// Scroll margin-top

.mt-scroll {
  --#{$prefix}scroll-margin-top: #{$scroll-margin-top};
  scroll-margin-top: var(--#{$prefix}scroll-margin-top) !important;
}
/* stylelint-enable @stylistic/value-list-max-empty-lines, @stylistic/function-max-empty-lines, declaration-no-important */
