// Binded content
//
// Bind different content to different navs or even accordion.

// stylelint-disable
.binded-content {
  position: relative;
}

.binded-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: opacity, transform;
  transition: opacity .3s ease-in-out, transform .3s ease-in-out;
  transform: scale(.95);
  opacity: 0;
  pointer-events: none;
  cursor: default;

  &.active {
    position: relative;
    transform: none;
    opacity: 1;
    z-index: 5;
    cursor: initial;
    pointer-events: initial;
  }
}
// stylelint-enable
