// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-hover-color and $btn-padding-y-sm.

// Import Bootstrap functions to use inside variables values
@import "bootstrap/scss/functions";


// Color system

// Grayscale
$white:    #fff !default;
$gray-50:  #f8fafc !default;
$gray-100: #f1f5f9 !default;
$gray-200: #e2e8f0 !default;
$gray-300: #cbd5e1 !default;
$gray-400: #94a3b8 !default;
$gray-500: #64748b !default;
$gray-600: #475569 !default;
$gray-700: #334155 !default;
$gray-800: #1e293b !default;
$gray-900: #020617 !default;
$black:    #000 !default;

// Disable Bootstrap's default $colors map
$colors: () !default;

// Disable Bootstrap's default $grays map
$grays: () !default;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   2 !default;

// Theme colors
$primary:       #ff4e02 !default;
$secondary:     $gray-500 !default;
$success:       #44b288 !default;
$info:          #44a5b2 !default;
$warning:       #efb572 !default;
$danger:        #ec5a5a !default;
$light:         $white !default;
$dark:          $gray-800 !default;

$primary-text-emphasis:       darken($primary, 10%) !default;
$secondary-text-emphasis:     $gray-700 !default;
$success-text-emphasis:       darken($success, 10%) !default;
$danger-text-emphasis:        darken($danger, 10%) !default;
$warning-text-emphasis:       darken($warning, 10%) !default;
$info-text-emphasis:          darken($info, 10%) !default;
$light-text-emphasis:         $gray-700 !default;
$dark-text-emphasis:          $gray-900 !default;

$primary-bg-subtle:           tint-color($primary, 85%) !default;
$secondary-bg-subtle:         $gray-50 !default;
$success-bg-subtle:           tint-color($success, 85%) !default;
$danger-bg-subtle:            tint-color($danger, 85%) !default;
$warning-bg-subtle:           tint-color($warning, 85%) !default;
$info-bg-subtle:              tint-color($info, 75%) !default;
$light-bg-subtle:             $white !default;
$dark-bg-subtle:              tint-color($dark, 90%) !default;

$primary-border-subtle:       tint-color($primary, 70%) !default;
$secondary-border-subtle:     $gray-200 !default;
$success-border-subtle:       tint-color($success, 60%) !default;
$danger-border-subtle:        tint-color($danger, 60%) !default;
$warning-border-subtle:       tint-color($warning, 70%) !default;
$info-border-subtle:          tint-color($info, 60%) !default;
$light-border-subtle:         $gray-100 !default;
$dark-border-subtle:          $gray-300 !default;

// User selection color
$user-selection-color:        rgba($gray-900, .1) !default;

// Gradient
$gradient:                    linear-gradient(0deg, $white 0, #ecf2f8 50%, $white 100%) !default;


// Options
//
// Modifying Bootstrap global options

$enable-rounded:              true !default;
$enable-shadows:              true !default;
$enable-negative-margins:     true !default;
$enable-dark-mode:            false !default;


// Prefix for CSS variables and icons

$prefix:                      ec- !default;
$icon-prefix:                 gi- !default;


// Spacing

$spacer:                      1rem !default;


// Scroll margin

$scroll-margin-top:           96px !default;


// Body
// Settings for the `<body>` element.

$body-color:                  $gray-900 !default;
$body-bg:                     $white !default;

$body-secondary-color:        $gray-600 !default;
$body-secondary-bg:           $gray-50 !default;

$body-tertiary-color:         $gray-400 !default;
$body-tertiary-bg:            $gray-100 !default;

$body-emphasis-color:         $gray-800 !default;


// Override Bootstrap's links

$link-color:                  $gray-900 !default;
$link-hover-color:            $gray-800 !default;
$link-shade-percentage:       0% !default;
$link-decoration:             underline !default;
$link-hover-decoration:       none !default;


// Grid breakpoints
//
// Modifying default Bootstrap's $grid-breakpoints map

$grid-breakpoints: (
  xs: 0,
  sm: 500px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;


// Grid containers
//
// Modifying default Bootstrap's $container-max-widths map (max-width of .container)
$container-max-widths: (
  sm: 95%,
  md: 95%,
  lg: 95%,
  xl: 95%,
  xxl: 1328px
) !default;


// Container padding

$container-padding-x:         2rem !default;


// Components
//
// Define common padding, border radius sizes, shadows and more.

$border-width:                1px !default;
$border-color:                $gray-100 !default;
$border-color-translucent:    rgba($black, .1) !default;

$border-radius:               .5rem !default;
$border-radius-xs:            calc(var(--#{$prefix}border-radius) * .5) !default;
$border-radius-sm:            calc(var(--#{$prefix}border-radius) * .75) !default;
$border-radius-lg:            calc(var(--#{$prefix}border-radius) * 1.5) !default;
$border-radius-xl:            calc(var(--#{$prefix}border-radius) * 2) !default;
$border-radius-xxl:           calc(var(--#{$prefix}border-radius) * 3) !default;
$border-radius-pill:          50rem !default;

$box-shadow:                  0 .5rem 1.5rem 0 rgba(50, 73, 96, .06) !default;
$box-shadow-sm:               0 .375rem 1.5rem rgba(#676f7b, .06) !default;
$box-shadow-lg:               0 1.125rem 3rem -.375rem rgba(#676f7b, .12) !default;
$box-shadow-inset:            unset !default;

$underline-thickness:         1px !default;

$component-color:             $gray-900 !default;
$component-hover-color:       $gray-900 !default;
$component-active-color:      $gray-900 !default;
$component-hover-bg:          $gray-100 !default;
$component-active-bg:         $gray-100 !default;
$component-disabled-color:    $gray-50 !default;

$focus-ring-width:            .25rem !default;
$focus-ring-opacity:          .1 !default;
$focus-ring-color:            rgba($gray-900, $focus-ring-opacity) !default;
$focus-ring-blur:             0 !default;
$focus-ring-box-shadow:       0 0 $focus-ring-blur $focus-ring-width var(--#{$prefix}focus-ring-color) !default;

$caret-width:                 1.275em !default;
$caret-spacing:               .15em !default;


// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:      "Poppins", sans-serif !default;

$font-size-root:              1rem !default;
$font-size-base:              $font-size-root !default;
$font-size-xl:                $font-size-base * 1.25 !default;
$font-size-lg:                $font-size-base * 1.125 !default;
$font-size-sm:                $font-size-base * .875 !default;
$font-size-xs:                $font-size-base * .75 !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-medium:          500 !default;
$font-weight-semibold:        600 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          $font-weight-medium !default;

$font-weight-base:            $font-weight-light !default;

$line-height-base:            1.5 !default;
$line-height-sm:              1.25 !default;
$line-height-lg:              2 !default;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2.25 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;

// Modifying default Bootstrap's $font-sizes map
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  "xl": $font-size-xl,
  "lg": $font-size-lg,
  "base": $font-size-base,
  "sm": $font-size-sm,
  "xs": $font-size-xs
) !default;

$headings-font-weight:        $font-weight-medium !default;
$headings-margin-bottom:      $spacer !default;
$headings-color:              $gray-900 !default;

$h1-line-height:              1.25 !default;
$h2-line-height:              1.3 !default;
$h3-line-height:              1.35 !default;
$h4-line-height:              1.4 !default;
$h5-line-height:              1.45 !default;
$h6-line-height:              1.5 !default;

// Modifying default Bootstrap's $display-font-sizes map
$display-font-sizes: (
  1: $font-size-base * 5,
  2: $font-size-base * 4.5,
  3: $font-size-base * 4,
  4: $font-size-base * 3.5,
  5: $font-size-base * 3,
  6: $font-size-base * 2.75
) !default;

$display-font-weight:         $font-weight-semibold !default;
$display-line-height:         $h1-line-height !default;

$lead-font-size:              $font-size-base * 1.5 !default;
$lead-font-weight:            $font-weight-base !default;

$blockquote-margin-y:         $spacer * 1.5 !default;
$blockquote-color:            var(--#{$prefix}heading-color) !default;
$blockquote-font-size:        $font-size-xl !default;
$blockquote-font-weight:      $font-weight-semibold !default;
$blockquote-footer-color:     var(--#{$prefix}body-color) !default;
$blockquote-footer-font-size: $font-size-sm !default;

$hr-margin-y:                 $spacer * 1.5 !default;
$hr-color:                    var(--#{$prefix}border-color) !default;
$hr-opacity:                  1 !default;

$legend-margin-bottom:        $spacer !default;
$legend-font-size:            1.125rem !default;
$legend-color:                var(--#{$prefix}heading-color) !default;
$legend-font-weight:          $headings-font-weight !default;

$dt-font-weight:              $font-weight-semibold !default;
$dt-color:                    var(--#{$prefix}heading-color) !default;

$list-inline-padding:         $spacer !default;


// Tables

$table-cell-padding-y:        .75rem !default;
$table-cell-padding-x:        .75rem !default;
$table-cell-padding-y-sm:     .375rem !default;
$table-cell-padding-x-sm:     .375rem !default;

$table-th-font-weight:        $headings-font-weight !default;
$table-th-color:              var(--#{$prefix}heading-color) !default;

$table-striped-bg-factor:     .05 !default;
$table-striped-bg:            rgba($gray-700, $table-striped-bg-factor) !default;

$table-active-bg-factor:      .1 !default;
$table-active-bg:             rgba($gray-700, $table-active-bg-factor) !default;

$table-hover-bg-factor:       .075 !default;
$table-hover-bg:              rgba($gray-700, $table-hover-bg-factor) !default;

// Modifying default Bootstrap's $table-variants map
$table-variants: (
  "dark": $dark,
) !default;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         .53rem !default;
$input-btn-padding-x:         1rem !default;
$input-btn-font-size:         $font-size-sm !default;

$input-btn-padding-y-sm:      .375rem !default;
$input-btn-padding-x-sm:      .875rem !default;
$input-btn-font-size-sm:      $font-size-xs !default;

$input-btn-padding-y-lg:      .685rem !default;
$input-btn-padding-x-lg:      1.125rem !default;
$input-btn-font-size-lg:      $font-size-base !default;

$input-btn-focus-box-shadow:  unset !default;
$input-btn-focus-width:       0 !default;


// Buttons

$btn-color:                   var(--#{$prefix}component-color) !default;
$btn-font-weight:             $font-weight-medium !default;
$btn-line-height:             1.125rem !default;
$btn-white-space:             nowrap !default;

$btn-padding-y:               .75rem !default;
$btn-padding-x:               1.5rem !default;
$btn-padding-y-sm:            .5rem !default;
$btn-padding-x-sm:            1rem !default;
$btn-padding-y-lg:            1.125rem !default;
$btn-padding-x-lg:            1.75rem !default;

$btn-disabled-opacity:        1 !default;

$btn-link-decoration:         none !default;
$btn-link-hover-decoration:   none !default;
$btn-link-decoration-thickness: .5px !default;
$btn-link-underline-offset:   .125rem !default;
$btn-link-disabled-color:     var(--#{$prefix}component-disabled-color) !default;

$btn-border-radius:           var(--#{$prefix}border-radius-lg) !default;
$btn-border-radius-sm:        var(--#{$prefix}border-radius) !default;
$btn-border-radius-lg:        var(--#{$prefix}border-radius-xl) !default;

$btn-box-shadow:              none !default;
$btn-active-box-shadow:       none !default;

$btn-transition:              color .25s ease-in-out, background-color .25s ease-in-out, border-color .25s ease-in-out, opacity .25s ease-in-out !default;


// Close button

$btn-close-color:             $gray-900 !default;
$btn-close-opacity:           .5 !default;
$btn-close-hover-opacity:     1 !default;
$btn-close-focus-opacity:     1 !default;
$btn-close-bg:                url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'><path d='M15.281 14.219a.75.75 0 0 1 .22.531.75.75 0 0 1-.22.531.75.75 0 0 1-.531.22.75.75 0 0 1-.531-.22L8 9.06l-6.219 6.22a.75.75 0 0 1-1.061 0 .75.75 0 0 1 0-1.061L6.94 8 .72 1.781a.75.75 0 0 1 0-1.061.75.75 0 0 1 1.061 0L8 6.94 14.22.719a.75.75 0 0 1 1.061 0 .75.75 0 0 1 0 1.061L9.061 8l6.22 6.219z' fill='#{$btn-close-color}'/></svg>") !default;
$btn-close-transition:        opacity .25s ease-in-out !default;


// Icon buttons

$btn-icon-size:               $font-size-base * 2.75 !default;
$btn-icon-size-sm:            $font-size-base * 2.125 !default;
$btn-icon-size-lg:            $font-size-base * 3.25 !default;

$btn-icon-font-size:          $font-size-base !default;
$btn-icon-font-size-sm:       $font-size-base !default;
$btn-icon-font-size-lg:       $font-size-base * 1.25 !default;


// Scroll to top button

$btn-scroll-top-size:                   2.75rem !default;
$btn-scroll-top-icon-font-size:         $font-size-base * 1.5 !default;
$btn-scroll-top-bg:                     rgba($gray-900, .25) !default;
$btn-scroll-top-hover-bg:               rgba($gray-900, .5) !default;
$btn-scroll-top-color:                  $white !default;
$btn-scroll-top-hover-color:            $white !default;
$btn-scroll-top-transition:             bottom 400ms cubic-bezier(.68, -.55, .265, 1.55), opacity .3s, background-color .25s ease-in-out !default;


// Forms

$form-text-margin-top:                  .375rem !default;
$form-text-font-size:                   $font-size-xs !default;

$form-label-font-size:                  $font-size-sm !default;
$form-label-font-weight:                $font-weight-normal !default;
$form-label-color:                      $headings-color !default;

$input-padding-x:                       $input-btn-padding-x !default;
$input-bg:                              $white !default;
$input-color:                           var(--#{$prefix}body-secondary-color) !default;
$input-border-color:                    $gray-300 !default;
$input-focus-border-color:              $gray-900 !default;

$input-disabled-color:                  var(--#{$prefix}tertiary-color) !default;
$input-disabled-bg:                     var(--#{$prefix}tertiary-bg) !default;
$input-disabled-border-color:           var(--#{$prefix}border-color) !default;

$input-border-radius:                   var(--#{$prefix}border-radius-lg) !default;
$input-border-radius-sm:                var(--#{$prefix}border-radius-lg) !default;
$input-border-radius-lg:                var(--#{$prefix}border-radius-lg) !default;

$input-placeholder-color:               $gray-400 !default;

$form-check-margin-bottom:              .25rem !default;
$form-check-input-bg:                   transparent !default;
$form-check-input-border:               var(--#{$prefix}border-width) solid $gray-400 !default;
$form-check-input-border-radius:        .25rem !default;
$form-check-input-checked-color:        $white !default;
$form-check-input-checked-bg-color:     $primary !default;
$form-check-input-checked-border-color: $primary !default;
$form-check-input-focus-box-shadow:     none !default;

$form-check-input-indeterminate-color:  $form-check-input-checked-color !default;
$form-check-input-indeterminate-bg-color: $form-check-input-checked-bg-color !default;
$form-check-input-indeterminate-border-color: $form-check-input-checked-border-color !default;

$form-switch-width:                     2.75rem !default;
$form-switch-height:                    1.5rem !default;
$form-switch-padding-start:             $form-switch-width + .625rem !default;
$form-switch-margin-bottom:             .75rem !default;
$form-switch-color:                     $white !default;
$form-switch-focus-color:               $form-switch-color !default;
$form-switch-checked-color:             $form-switch-color !default;
$form-switch-bg-color:                  $gray-300 !default;
$form-switch-checked-bg-color:          $success !default;

$form-select-bg-size:                   15px 11px !default;
$form-select-padding-x:                 $input-padding-x !default;
$form-select-indicator-padding:         $form-select-padding-x * 2.25 !default;
$form-select-indicator-color:           $gray-600 !default;
$form-select-indicator:                 url("data:image/svg+xml,<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14 5L7.64706 11L2 5' stroke='#{$form-select-indicator-color}'/></svg>") !default;

$form-range-track-height:               .125rem !default;
$form-range-track-bg:                   var(--#{$prefix}border-color) !default;
$form-range-thumb-width:                .5rem !default;
$form-range-thumb-bg:                   var(--#{$prefix}body-bg) !default;
$form-range-thumb-box-shadow:           0 0 0 .125rem var(--#{$prefix}emphasis-color) !default;
$form-range-thumb-active-bg:            var(--#{$prefix}emphasis-color) !default;
$form-range-thumb-disabled-bg:          var(--#{$prefix}secondary-bg) !default;

$form-feedback-valid-color:             $success !default;
$form-feedback-invalid-color:           $danger !default;
$form-feedback-icon-valid-color:        $form-feedback-valid-color !default;
$form-feedback-icon-invalid-color:      $form-feedback-invalid-color !default;
$form-feedback-icon-valid:              url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$form-feedback-icon-valid-color}'><path d='M10.2,3.8c1.8-0.4,3.6-0.2,5.3,0.5c0.4,0.2,0.9,0,1.1-0.4s0-0.9-0.4-1.1c0,0,0,0,0,0c-5-2.2-10.9,0-13.2,5.1s0,10.9,5.1,13.2s10.9,0,13.2-5.1c0.6-1.3,0.9-2.7,0.9-4.1v-0.8c0-0.4-0.4-0.8-0.8-0.8s-0.8,0.4-0.8,0.8l0,0V12c0,4.6-3.8,8.4-8.4,8.4S3.6,16.6,3.6,12C3.6,8.1,6.3,4.7,10.2,3.8z'/><path d='M21.8,5.2c0.3-0.3,0.3-0.8,0-1.1c-0.3-0.3-0.8-0.3-1.1,0c0,0,0,0,0,0L12,12.7l-2.2-2.2c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l2.8,2.8c0.3,0.3,0.8,0.3,1.1,0c0,0,0,0,0,0L21.8,5.2L21.8,5.2z'/></svg>") !default;
$form-feedback-icon-invalid:            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$form-feedback-icon-invalid-color}'><path d='M12,1.5C6.2,1.5,1.5,6.2,1.5,12S6.2,22.5,12,22.5S22.5,17.8,22.5,12S17.8,1.5,12,1.5z M12,20.7c-4.8,0-8.7-4-8.7-8.7S7.2,3.3,12,3.3s8.7,4,8.7,8.7S16.8,20.7,12,20.7z'/><path d='M15.7,14.5c0.3,0.3,0.3,0.9,0,1.2s-0.9,0.3-1.2,0L12,13.2l-2.5,2.5c-0.3,0.3-0.9,0.3-1.2,0s-0.3-0.9,0-1.2l2.5-2.5L8.3,9.5c-0.4-0.3-0.4-0.9,0-1.2c0.3-0.4,0.9-0.4,1.2,0l2.5,2.5l2.5-2.5c0.3-0.3,0.9-0.3,1.2,0s0.3,0.9,0,1.2L13.2,12L15.7,14.5z'/></svg>") !default;

$form-feedback-tooltip-padding-y:       .25rem !default;
$form-feedback-tooltip-padding-x:       .5rem !default;
$form-feedback-tooltip-font-size:       $font-size-xs !default;


// Navs

$nav-link-padding-y:                    .75rem !default;
$nav-link-padding-x:                    1.25rem !default;
$nav-link-font-size:                    $font-size-sm !default;
$nav-link-font-weight:                  $font-weight-medium !default;
$nav-link-line-height:                  1.375rem !default;
$nav-link-color:                        var(--#{$prefix}component-color) !default;
$nav-link-hover-color:                  var(--#{$prefix}component-hover-color) !default;
$nav-link-active-color:                 var(--#{$prefix}component-active-color) !default;
$nav-link-disabled-color:               var(--#{$prefix}component-disabled-color) !default;
$nav-link-transition:                   color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out !default;

$nav-tabs-gap:                          $spacer * .5 !default;
$nav-tabs-border-radius:                $border-radius-lg !default;

$nav-tabs-bg:                           transparent !default;
$nav-tabs-border-color:                 transparent !default;
$nav-tabs-border-width:                 $border-width * 2 !default;

$nav-tabs-link-hover-bg:                transparent !default;
$nav-tabs-link-hover-color:             $primary !default;
$nav-tabs-link-hover-border-color:      transparent !default;

$nav-tabs-link-active-bg:               $primary-bg-subtle !default;
$nav-tabs-link-active-color:            $primary !default;
$nav-tabs-link-active-border-color:     $primary-border-subtle !default;
$nav-tabs-link-active-box-shadow:       none !default;

$nav-pills-gap:                         .5rem !default;
$nav-pills-border-radius:               .75rem !default;
$nav-pills-link-border-width:           var(--#{$prefix}border-width) !default;
$nav-pills-link-border-color:           transparent !default;
$nav-pills-link-hover-color:            var(--#{$prefix}component-hover-color) !default;
$nav-pills-link-hover-bg:               var(--#{$prefix}component-hover-bg) !default;
$nav-pills-link-hover-border-color:     var(--#{$prefix}component-hover-bg) !default;
$nav-pills-link-active-color:           var(--#{$prefix}component-active-color) !default;
$nav-pills-link-active-bg:              var(--#{$prefix}component-active-bg) !default;
$nav-pills-link-active-border-color:    var(--#{$prefix}component-active-bg) !default;
$nav-pills-link-disabled-color:         var(--#{$prefix}component-disabled-color) !default;
$nav-pills-link-disabled-border-color:  var(--#{$prefix}component-disabled-bg) !default;

$nav-underline-gap:                     .5rem 1.75rem !default;
$nav-underline-border-width:            var(--#{$prefix}border-width) !default;
$nav-underline-link-active-color:       var(--#{$prefix}component-active-color) !default;


// Navbar

$navbar-padding-y:                      $spacer * .75 !default;
$navbar-padding-x:                      0 !default;

$navbar-nav-link-padding-x:               1.25rem !default;
$navbar-nav-link-font-size:               $font-size-base !default;
$navbar-nav-link-underline-border-width:  var(--#{$prefix}underline-thickness) !default;

$navbar-brand-font-size:                1.5rem !default;
$navbar-brand-font-weight:              $font-weight-semibold !default;

$navbar-toggler-padding-y:              $spacer * .625 !default;
$navbar-toggler-padding-x:              $spacer * .25 !default;
$navbar-toggler-border-radius:          0 !default;
$navbar-toggler-bar-width:              1.375rem !default;
$navbar-toggler-bar-height:             .125rem !default;
$navbar-toggler-bar-spacing:            .3125rem !default;
$navbar-toggler-focus-width:            0 !default;

$navbar-light-color:                    $component-color !default;
$navbar-light-hover-color:              $component-hover-color !default;
$navbar-light-active-color:             $component-active-color !default;
$navbar-light-disabled-color:           $component-disabled-color !default;
$navbar-light-brand-color:              $gray-900 !default;
$navbar-light-brand-hover-color:        $gray-900 !default;
$navbar-light-toggler-border-color:     transparent !default;
$navbar-light-toggler-icon-bg:          initial !default;


// Dropdowns

$dropdown-min-width:                    12.5rem !default;
$dropdown-padding-x:                    .75rem !default;
$dropdown-padding-y:                    .75rem !default;
$dropdown-spacer:                       $spacer * .25 !default;
$dropdown-font-size:                    $font-size-sm !default;
$dropdown-color:                        var(--#{$prefix}component-color) !default;
$dropdown-border-color:                 var(--#{$prefix}light-border-subtle) !default;
$dropdown-divider-bg:                   var(--#{$prefix}border-color) !default;
$dropdown-divider-margin-y:             $spacer * .375 !default;
$dropdown-box-shadow:                   var(--#{$prefix}box-shadow) !default;

$dropdown-link-color:                   var(--#{$prefix}component-color) !default;
$dropdown-link-hover-color:             var(--#{$prefix}component-hover-color) !default;
$dropdown-link-hover-bg:                var(--#{$prefix}component-hover-bg) !default;
$dropdown-link-active-color:            var(--#{$prefix}component-active-color) !default;
$dropdown-link-active-bg:               var(--#{$prefix}component-active-bg) !default;
$dropdown-link-disabled-color:          var(--#{$prefix}component-disabled-color) !default;
$dropdown-link-transition:              color .2s ease-in-out, background-color .2s ease-in-out !default;

$dropdown-item-padding-y:               $spacer * .5 !default;
$dropdown-item-padding-x:               $spacer * .75 !default;
$dropdown-item-border-radius:           $border-radius-sm !default;
$dropdown-item-spacer:                  $spacer * .125 !default;

$dropdown-header-color:                 var(--#{$prefix}heading-color) !default;
$dropdown-header-padding-x:             $dropdown-item-padding-x !default;
$dropdown-header-padding-y:             $dropdown-item-padding-y !default;


// Pagination

$pagination-spacer:                     .5rem !default;
$pagination-margin-start:               0 !default;

$pagination-padding-y:                  0 !default;
$pagination-padding-x:                  0 !default;
$pagination-padding-y-sm:               0 !default;
$pagination-padding-x-sm:               0 !default;
$pagination-padding-y-lg:               0 !default;
$pagination-padding-x-lg:               0 !default;

$pagination-size:                       $btn-icon-size !default;
$pagination-size-sm:                    $btn-icon-size-sm !default;
$pagination-size-lg:                    $btn-icon-size-lg !default;

$pagination-border-radius:              $border-radius-lg !default;
$pagination-font-size:                  $font-size-sm !default;
$pagination-font-weight:                $btn-font-weight !default;

$pagination-color:                      var(--#{$prefix}component-color) !default;
$pagination-hover-color:                var(--#{$prefix}component-hover-color) !default;
$pagination-active-color:               var(--#{$prefix}component-active-color) !default;

$pagination-focus-bg:                   var(--#{$prefix}body-bg) !default;
$pagination-hover-bg:                   var(--#{$prefix}component-hover-bg) !default;
$pagination-active-bg:                  var(--#{$prefix}component-active-bg) !default;

$pagination-border-color:               $gray-200 !default;
$pagination-hover-border-color:         $gray-200 !default;
$pagination-active-border-color:        $gray-200 !default;


// Cards

$card-spacer-y:                         $spacer * 1.5 !default;
$card-spacer-x:                         $spacer * 1.5 !default;
$card-bg:                               $body-bg !default;
$card-cap-padding-y:                    $card-spacer-y !default;
$card-cap-padding-x:                    $card-spacer-x !default;
$card-cap-bg:                           $card-bg !default;
$card-title-spacer-y:                   $card-spacer-y * .5 !default;
$card-title-color:                      var(--#{$prefix}heading-color) !default;
$card-border-color:                     var(--#{$prefix}border-color) !default;
$card-border-radius:                    var(--#{$prefix}border-radius-xl) !default;


// Accordion

$accordion-padding-y:                   1.25rem !default;
$accordion-padding-x:                   0 !default;
$accordion-bg:                          transparent !default;
$accordion-border-radius:               0 !default;
$accordion-inner-border-radius:         0 !default;

$accordion-body-padding-y:              0 !default;
$accordion-body-padding-x:              0 !default;
$accordion-body-font-size:              $font-size-base !default;

$accordion-button-color:                var(--#{$prefix}heading-color) !default;
$accordion-button-font-size:            $font-size-base !default;
$accordion-button-font-weight:          $headings-font-weight !default;
$accordion-button-hover-color:          var(--#{$prefix}heading-color) !default;
$accordion-button-active-color:         var(--#{$prefix}heading-color) !default;
$accordion-button-active-bg:            transparent !default;
$accordion-transition:                  none !default;

$accordion-icon-width:                  1em !default;
$accordion-icon-color:                  $gray-900 !default;
$accordion-icon-active-color:           $gray-900 !default;
$accordion-button-icon:                 url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$accordion-icon-color}'><path d='M3.8,7.9c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7l6.7-6.7c0.4-0.4,1.1-0.4,1.5,0c0.4,0.4,0.4,1.1,0,1.5l-7.4,7.4c-0.4,0.4-1.1,0.4-1.5,0L3.8,9.4C3.3,9.1,3.3,8.4,3.8,7.9z'/></svg>") !default;
$accordion-button-active-icon:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$accordion-icon-active-color}'><path d='M3.8,7.9c0.4-0.4,1.1-0.4,1.5,0l6.7,6.7l6.7-6.7c0.4-0.4,1.1-0.4,1.5,0c0.4,0.4,0.4,1.1,0,1.5l-7.4,7.4c-0.4,0.4-1.1,0.4-1.5,0L3.8,9.4C3.3,9.1,3.3,8.4,3.8,7.9z'/></svg>") !default;
$accordion-button-icon-alt:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$accordion-icon-color}'><path d='M12 4.125c.483 0 .875.392.875.875v14c0 .483-.392.875-.875.875s-.875-.392-.875-.875V5c0-.483.392-.875.875-.875z'/><path d='M4.125 12c0-.483.392-.875.875-.875h14c.483 0 .875.392.875.875s-.392.875-.875.875H5c-.483 0-.875-.392-.875-.875z'/></svg>") !default;
$accordion-button-active-icon-alt:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='#{$accordion-icon-active-color}'><path d='M4.125 12c0-.483.392-.875.875-.875h14c.483 0 .875.392.875.875s-.392.875-.875.875H5c-.483 0-.875-.392-.875-.875z'/></svg>") !default;

$accordion-progress-bar-height:         .125rem !default;
$accordion-progress-bar-bg:             $gray-900 !default;


// Badges

$badge-padding-y:                       .5em !default;
$badge-padding-x:                       1em !default;
$badge-font-weight:                     $font-weight-normal !default;


// Carousel

$carousel-bullet-size:                  .5rem !default;
$carousel-bullet-bg:                    $gray-300 !default;
$carousel-bullet-active-bg:             $primary !default;
$carousel-bullet-opacity:               1 !default;
$carousel-bullet-active-opacity:        1 !default;
$carousel-bullet-border-radius:         50% !default;
$carousel-bullet-gap:                   .75rem !default;
$carousel-bullet-transition:            background-color .2s ease-in-out !default;

$carousel-progressbar-size:             .25rem !default;
$carousel-progressbar-border-radius:    $border-radius-pill !default;
$carousel-progressbar-bg:               $gray-100 !default;
$carousel-progressbar-fill-bg:          $gray-800 !default;

$carousel-scrollbar-size:               .25rem !default;
$carousel-scrollbar-border-radius:      $border-radius-pill !default;
$carousel-scrollbar-bg:                 $gray-100 !default;
$carousel-scrollbar-drag-bg:            $gray-800 !default;


// Custom scrollbar

$scrollbar-width:                       .125rem !default;
$scrollbar-border-radius:               $border-radius !default;
$scrollbar-track-bg:                    $gray-100 !default;
$scrollbar-bg:                          $gray-400 !default;


// Media image utility default values

$media-img-width-desktop:               11.25rem !default;
$media-img-width-tablet:                9rem !default;
$media-img-width-mobile:                7.75rem !default;


// Text truncation utility

$text-line-clamp-lines:                 2 !default;
$text-line-clamp-height:                calc(3em - 1px) !default;


// Steps

$step-progress-height:                  $border-width !default;
$step-progress-bg:                      $gray-200 !default;

$step-number-size:                      2.625rem !default;
$step-number-font-size:                 $font-size-sm !default;
$step-number-font-weight:               $font-weight-bold !default;
$step-number-border-radius:             50% !default;
$step-number-bg:                        $white !default;
$step-number-color:                     $body-color !default;
$step-number-box-shadow:                none !default;
$step-number-transition:                background-color .25s ease-in-out, color .2s ease-in-out, box-shadow .2s ease-in-out !default;

$step-label-color:                      $body-color !default;
$step-label-font-size:                  $font-size-xs !default;

$step-active-progress-bg:               $gray-900 !default;
$step-active-number-bg:                 $white !default;
$step-active-number-color:              $body-color !default;
$step-active-box-shadow:                none !default;
