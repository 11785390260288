// Effects with images


// Media image (adaptive size on breakpoint)

.media-img {
  --#{$prefix}media-img-width-desktop: #{$media-img-width-desktop};
  --#{$prefix}media-img-width-tablet: #{$media-img-width-tablet};
  --#{$prefix}media-img-width-mobile: #{$media-img-width-mobile};

  display: block;
  width: var(--#{$prefix}media-img-width-mobile);

  @include media-breakpoint-up(sm) {
    width: var(--#{$prefix}media-img-width-tablet);
  }
  @include media-breakpoint-up(lg) {
    width: var(--#{$prefix}media-img-width-desktop);
  }
}


// Fade-in-out element onhover

.hover-fade-item {
  transition: opacity .25s ease-in-out;

  @include media-breakpoint-up(lg) {
    opacity: 0;
  }
}

.hover-fade:hover .hover-fade-item {
  opacity: 1;
}
