// Extend default Bootstrap's .nav, .nav-item, .nav-link classes

.nav {
  --#{$prefix}nav-link-line-height: #{$nav-link-line-height};
  --#{$prefix}nav-link-active-color: #{$nav-link-active-color};

  flex-direction: row;
  gap: 0;
}

.nav-link {
  display: flex;
  align-items: center;

  &.show {
    color: var(--#{$prefix}nav-link-hover-color);
  }

  &.active {
    color: var(--#{$prefix}nav-link-active-color);
  }

  &.disabled,
  &:disabled {
    color: var(--#{$prefix}nav-link-disabled-color);
  }
}


// Tabs

.nav-tabs {
  --#{$prefix}nav-tabs-gap: #{$nav-tabs-gap};
  --#{$prefix}nav-tabs-bg: #{$nav-tabs-bg};
  --#{$prefix}nav-tabs-link-hover-bg: #{$nav-tabs-link-hover-bg};
  --#{$prefix}nav-tabs-link-hover-color: #{$nav-tabs-link-hover-color};
  --#{$prefix}nav-tabs-link-hover-border-color: #{$nav-tabs-link-hover-border-color};
  --#{$prefix}nav-tabs-link-active-color: #{$nav-tabs-link-active-color};
  --#{$prefix}nav-tabs-link-active-box-shadow: #{$nav-tabs-link-active-box-shadow};

  gap: var(--#{$prefix}nav-tabs-gap);
  background-color: var(--#{$prefix}nav-tabs-bg);

  .nav-link {
    line-height: var(--#{$prefix}nav-link-line-height);
    @include border-radius(var(--#{$prefix}nav-tabs-border-radius));

    &:hover:not(.active) {
      color: var(--#{$prefix}nav-tabs-link-hover-color);
      border-color: var(--#{$prefix}nav-tabs-link-hover-border-color);
    }

    &:hover,
    &.show,
    &:focus-visible {
      background-color: var(--#{$prefix}nav-tabs-link-hover-bg);
    }

    &:focus-visible {
      box-shadow: $nav-link-focus-box-shadow !important; // stylelint-disable-line declaration-no-important
    }

    &.active {
      color: var(--#{$prefix}nav-tabs-link-active-color);
      background-color: var(--#{$prefix}nav-tabs-link-active-bg);
      @include box-shadow(var(--#{$prefix}nav-tabs-link-active-box-shadow));
    }

    &.disabled,
    &:disabled {
      background: none;
    }
  }

  &.flex-column .nav-link {
    justify-content: start;
  }

  .dropdown-menu {
    @include border-radius(var(--#{$prefix}dropdown-border-radius));
  }
}


// Pills

.nav-pills {
  --#{$prefix}nav-pills-gap: #{$nav-pills-gap};
  --#{$prefix}nav-pills-link-border-width: #{$nav-pills-link-border-width};
  --#{$prefix}nav-pills-link-border-color: #{$nav-pills-link-border-color};
  --#{$prefix}nav-pills-link-hover-color: #{$nav-pills-link-hover-color};
  --#{$prefix}nav-pills-link-hover-bg: #{$nav-pills-link-hover-bg};
  --#{$prefix}nav-pills-link-hover-border-color: #{$nav-pills-link-hover-border-color};
  --#{$prefix}nav-pills-link-active-border-color: #{$nav-pills-link-active-border-color};
  --#{$prefix}nav-pills-link-disabled-color: #{$nav-pills-link-disabled-color};
  --#{$prefix}nav-pills-link-disabled-border-color: #{$nav-pills-link-border-color};

  gap: var(--#{$prefix}nav-pills-gap);

  .nav-link {
    line-height: var(--#{$prefix}nav-link-line-height);
    border: var(--#{$prefix}nav-pills-link-border-width) solid var(--#{$prefix}nav-pills-link-border-color);

    &:hover,
    &.show,
    &:focus-visible {
      color: var(--#{$prefix}nav-pills-link-hover-color);
      background-color: var(--#{$prefix}nav-pills-link-hover-bg);
      border-color: var(--#{$prefix}nav-pills-link-hover-border-color);
    }

    &.active {
      border-color: var(--#{$prefix}nav-pills-link-active-border-color);
    }

    &.disabled,
    &:disabled {
      color: var(--#{$prefix}nav-pills-link-disabled-color);
      border-color: var(--#{$prefix}nav-pills-link-disabled-border-color);
    }
  }
}


// Fill and justify

.nav-fill:not(.flex-column) .nav-link,
.nav-justified:not(.flex-column) .nav-link {
  justify-content: center;
}

.nav.flex-column {
  .nav-link {
    display: inline-flex;
  }
}


// Page nav

.page-nav {
  --#{$prefix}nav-link-padding-x: #{$spacer};
  --#{$prefix}nav-link-active-color: #{$primary};

  .nav-link {
    position: relative;

    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      display: block;
      width: $border-width * 2;
      height: 100%;
      content: "";
      background-color: transparent;
      transition: background-color .25s ease-in-out;
    }

    &.active::before {
      background-color: $primary;
    }
  }
}
