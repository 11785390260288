// Override Bootstrap's pagination component

.pagination {
  --#{$prefix}pagination-spacer: #{$pagination-spacer};
  --#{$prefix}pagination-size: #{$pagination-size};
  --#{$prefix}pagination-font-weight: #{$pagination-font-weight};

  flex-direction: row;
  gap: var(--#{$prefix}pagination-spacer);
  margin-bottom: 0;
}

.page-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--#{$prefix}pagination-size);
  height: var(--#{$prefix}pagination-size);
  font-weight: var(--#{$prefix}pagination-font-weight);
}


// Sizing

.pagination-sm {
  --#{$prefix}pagination-size: #{$pagination-size-sm};
}

.pagination-lg {
  --#{$prefix}pagination-size: #{$pagination-size-lg};
}
